<template>
<ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
        <b-overlay :show="loadingTab4">
            <div class="vertical-middle">
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১. চা উন্নয়ন সংক্রান্ত তথ্যঃ ' : '1. Development Information on Tea: ' }} ({{getFiscalYear(fiscalYearId)}})</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '(ক) সেকশনের নাম্বার সহ ইনফিলিং করা চারার সংখ্যা' : '(a) Number of plants infilling with section number' }}</b-th>
                                <b-td>
                                    <b-form-input
                                    v-model="development_activities.development_infos.infilling_plan_no"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                    <br>
                                    <b-form-input
                                    :placeholder="$t('tradeTariffConfig.section_no')"
                                    v-model="development_activities.development_infos.section_no"
                                    >
                                    </b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '(খ) পরবর্তী বছর সম্প্রসারণ করার জন্য জমি পরিস্কার' : '(b) Clearing of land for next year expansion' }}<small class="text-muted">{{ currentLocale === 'bn' ? '(একর)' : '(Acre)' }}</small></b-th>
                                <b-td>
                                    <b-form-input
                                       v-model="development_activities.development_infos.land_clearing"
                                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '(গ) পুরাতন চা গাছ উৎপাদন' : '(c) Production of old tea plants' }}</b-th>
                                 <b-td>
                                    <b-form-input
                                       v-model="development_activities.development_infos.old_land_plant_production"
                                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '(ঘ) জমি পুনর্বাসন' : '(d) Land rehabilitation' }}<small class="text-muted">{{ currentLocale === 'bn' ? '(একর)' : '(Acre)' }}</small></b-th>
                                <b-td>
                                    <b-form-input
                                       v-model="development_activities.development_infos.land_rehabilitation"
                                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                  <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.development_infos.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.development_infos.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '২. নার্সারী' : '2. Nursery' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? 'জাত' : 'Caste' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'প্রাইমারি বেডে অঙ্কুরিত চারা (ক)' : 'Seedlings germinated in primary bed (a)' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? '২য় পর্যায়ের পলিটিউবে স্থাপিত/ অঙ্কুরিত চারা (খ)' : 'Seedlings placed/germinated in 2nd stage polytubes (b)' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'রোপণযোগ্য (গ)' : 'plantable (c)' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'মোট (ক+খ+গ)' : 'Total (A+B+C)' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'সংগ্রহের উৎস' : 'source of collection' }}</b-th>
                                <b-th>{{$t('globalTrans.action')}}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="development_activities.nurseries.details.length > 0">
                            <b-tr v-for="(nursery, index) in development_activities.nurseries.details" :key="index">
                                 <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')"  v-model="nursery.caste_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')"  v-model="nursery.caste_bn"></b-form-input>
                                 </b-td>
                                  <b-td>
                                    <b-form-input
                                        @keyup="calTotalNurseryData(development_activities.nurseries.details,1)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        v-model="nursery.primary_germinated_seedlings"
                                    ></b-form-input>
                                  </b-td>
                                  <b-td>
                                    <b-form-input
                                        v-model="nursery.secondary_germinated_seedlings" @keyup="calTotalNurseryData(development_activities.nurseries.details,2)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                  </b-td>
                                  <b-td>
                                    <b-form-input
                                        v-model="nursery.plantable_seedlings"
                                        @keyup="calTotalNurseryData(development_activities.nurseries.details,3)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        >
                                    </b-form-input>
                                  </b-td>
                                  <b-td>{{$n(parseFloat(nursery.primary_germinated_seedlings) + parseFloat(nursery.secondary_germinated_seedlings) + parseFloat(nursery.plantable_seedlings))}}</b-td>
                                  <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="nursery.collection_source_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="nursery.collection_source_bn"></b-form-input>
                                 </b-td>
                                  <b-td>
                                   <button
                                    v-if="index === development_activities.nurseries.details.length - 1"
                                    @click="addMoreNurseries(development_activities.nurseries.details)"
                                    class="btn btn-sm btn-success float-right my-2"
                                    type="button"
                                    ><i class="ri-add-fill"></i> {{$t('globalTrans.add_more')}}</button>
                                    <b-button v-if="index !== development_activities.nurseries.details.length - 1 && index >= 4" class="btn btn-danger" size="sm" @click="removeNurseries(development_activities.nurseries.details, index)" :title="$t('globalTrans.delete')">
                                    <i class="ri-close-line"></i></b-button>
                                </b-td>
                            </b-tr>
                                <b-tr class="text-center">
                                <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                                <b-td><strong>{{$n(development_activities.nurseries.total_primary_germinated_seedlings)}}</strong></b-td>
                                <b-td><strong>{{$n(development_activities.nurseries.total_secondary_germinated_seedlings)}}</strong></b-td>
                                <b-td><strong>{{$n(development_activities.nurseries.total_plantable_seedlings)}}</strong></b-td>
                                <b-td><strong>{{ $n(development_activities.nurseries.grand_total_seedlings)}}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <!-- <b-td><strong>{{$n(tea_planting_info.newTeaData.new_tea_tot_tea_prod_ready_tea)}}</strong></b-td> -->
                                <b-td></b-td>
                            </b-tr>
                            <b-tr>
                                 <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.nurseries.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.nurseries.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৩. চা ব্যতীত অন্য গাছপালা রোপণ (একর)/সংখ্যাঃ' : '3. Plantation of crops other than tea (Acres)/No' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-td><b-form-input
                                 oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                 v-model="development_activities.common_data.crop_plantation"
                                 ></b-form-input></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৪. রাবার আবাদ সম্পর্কিতঃ' : '4. Regarding rubber plantation:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? 'রাবার আবাদের অনুমতি নেয়া হয়েছে কিনা' : 'Whether permission has been taken for rubber plantation' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'রাবার আবাদে জমির পরিমাণ (একর)' : 'Amount of land under rubber plantation (acres)' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'রাবার উৎপাদনের পরিমাণ' : 'Volume of rubber production' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'রাবার আবাদ হতে প্রাপ্ত অর্থের পরিমাণ' : 'Amount of money received from rubber plantations' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'উৎপাদিত রাবার কোথায় বিক্রয় করা হয়েছে' : 'Where is the manufactured rubber sold?' }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-form-radio-group v-model="development_activities.rubber_plantations.has_permission">
                                    <b-form-radio value="1">{{$t('globalTrans.yes')}}</b-form-radio>
                                    <b-form-radio value="0">{{$t('globalTrans.no')}}</b-form-radio>
                                </b-form-radio-group>
                                 </b-td>
                                <b-td>
                                    <b-form-input
                                        v-model="development_activities.rubber_plantations.land_amount"
                                         oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                 </b-td>
                                <b-td>
                                    <b-form-input
                                        v-model="development_activities.rubber_plantations.production_volume"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                 </b-td>
                                <b-td>
                                    <b-form-input
                                        v-model="development_activities.rubber_plantations.money_amount"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                 </b-td>
                                <b-td>
                                    <b-form-input
                                      :placeholder="$t('globalTrans.en')"
                                      v-model="development_activities.rubber_plantations.rubber_sale_place_en"
                                      ></b-form-input>
                                    <br>
                                   <b-form-input
                                      :placeholder="$t('globalTrans.bn')"
                                      v-model="development_activities.rubber_plantations.rubber_sale_place_bn"
                                      ></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.rubber_plantations.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.rubber_plantations.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৫. উন্নয়ন কর্মকূচী বাস্তবায়নের জন্য কোন ব্যাংক থেকে কি পরিমাণ ঋণ গ্রহণ করা হয়েছে (ত্রিপক্ষীয় চুক্তিপত্রের কপি)' : '5. How much loan has been taken from any bank for implementation of development projects (copy of tripartite agreement)' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-form-input
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    v-model="development_activities.common_data.loan_amount"
                                    >
                                   </b-form-input>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৬. বনজ সম্পদ কর্তন সম্পর্কিতঃ' : '6. Regarding Deduction of Forest Resources:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? 'ইতোপূর্বে সর্বশেষ কখন বনজ সম্পদ কর্তন করা হয়েছে' : 'When was the last time forest resources were cut in the past?' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'বাংলাদেশ চা বোর্ডের অনুমতি ছিল কিনা (তারিখ ও স্মারক নং উল্লেখসহ)' : 'Whether permission of Bangladesh Tea Board (with mention of date and memo no.)' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'বনজ সম্পদ কর্তনের পরিমাণ (সংখ্যা)' : 'Amount of forest resource cut (No.)' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'বনজ সম্পদ বিক্রয় হতে প্রাপ্ত অর্থের পরিমাণ' : 'Amount of money received from sale of forest resources' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'বনজ সম্পদ বিক্রয় হতে প্রাড্ত অর্থ দ্বারা চা বাগানের উন্নয়ন মূলক কাজের বিবরণ' : 'Details of tea garden development work with proceeds from sale of forest assets' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'উক্ত অর্থ দাবারা সরকার/ বাংলাদেশ চা বোর্ড/ব্যাংক এর ঋণ পরিশোধ করা হলে তার পরিমাণ' : 'The said amount is the amount if the debt of Dabara Government/Bangladesh Tea Board/Bank is paid' }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-form-input
                                      :placeholder="$t('globalTrans.en')"
                                      v-model="development_activities.forest_resources.cutting_time_info_en"
                                      ></b-form-input>
                                    <br>
                                   <b-form-input
                                      :placeholder="$t('globalTrans.bn')"
                                      v-model="development_activities.forest_resources.cutting_time_info_bn"
                                      ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-radio-group v-model="development_activities.forest_resources.has_permission">
                                        <b-form-radio value="1">{{$t('globalTrans.yes')}}</b-form-radio>
                                        <b-form-radio value="0">{{$t('globalTrans.no')}}</b-form-radio>
                                    </b-form-radio-group>
                                    <br>
                                    <date-picker
                                        id="request_date"
                                        v-model="development_activities.forest_resources.permission_date"
                                        class="form-control"
                                        :placeholder="$t('globalTrans.select_date')"
                                    >
                                    </date-picker>
                                    <br>
                                     <b-form-input
                                        v-model="development_activities.forest_resources.memo_no"
                                    >
                                    </b-form-input>
                                 </b-td>
                                <b-td>
                                    <b-form-input
                                        v-model="development_activities.forest_resources.forest_resource_amount"
                                         oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                 </b-td>
                                <b-td>
                                    <b-form-input
                                        v-model="development_activities.forest_resources.money_amount"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                 </b-td>
                                <b-td>
                                    <b-form-input
                                      :placeholder="$t('globalTrans.en')"
                                      v-model="development_activities.forest_resources.development_work_details_en"
                                      ></b-form-input>
                                    <br>
                                   <b-form-input
                                      :placeholder="$t('globalTrans.bn')"
                                      v-model="development_activities.rubber_plantations.development_work_details_bn"
                                      ></b-form-input>
                                 </b-td>
                                 <b-td>
                                    <b-form-input
                                        v-model="development_activities.forest_resources.loan_amount"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    >
                                    </b-form-input>
                                 </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.forest_resources.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.forest_resources.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. দাপ্তরিক কাজে চা বাগান পরিদর্শন সম্পর্কিত (বিগত ০৩ বছর)' : '7. Visiting tea gardens on official work (last 03 years)' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'পরিদর্শনের কারণ' : 'Reason for inspection' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'পরিদর্শনকালীন প্রদত্ত পরামর্শের বিবরণ' : 'Details of advice given during visit' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'পরামর্শ অনুযায়ী সম্পাদিত কাজের বিবরণ ও ফলাফল' : 'Details of work done and results as per suggestion' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(ক) বাংলাদেশ চা বোর্ড, প্রধান কার্যালয়' : '(a) Bangladesh Tea Board, Head Office' }}</b-td>
                                <b-td>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.en')"
                                     v-model="development_activities.visiting_tea_garden.btb_inspection_reason_en"
                                     ></b-form-input>
                                     <br>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.bn')"
                                     v-model="development_activities.visiting_tea_garden.btb_inspection_reason_bn"
                                     ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.btb_advice_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.btb_advice_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.btb_work_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.btb_work_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')"  v-model="development_activities.visiting_tea_garden.btb_comment_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')"  v-model="development_activities.visiting_tea_garden.btb_comment_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(খ) বাংলাধেশ চা গবেষণা ইনস্টিটিউট (বিটিআরআই)- এর বিজ্ঞানীগণ কর্তৃক উপদেশমূলক পরিদর্শন' : '(b) Advisory visit by scientists of Bangladesh Tea Research Institute (BTRI).' }}</b-td>
                                <b-td>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.en')"
                                     v-model="development_activities.visiting_tea_garden.btri_inspection_reason_en"
                                     ></b-form-input>
                                     <br>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.bn')"
                                     v-model="development_activities.visiting_tea_garden.btri_inspection_reason_bn"
                                     ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.btri_advice_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.btri_advice_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.btri_work_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.btri_work_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.btri_comment_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.btri_comment_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(গ) প্রকল্প উন্নয়ন ইউনিট (পিডিইউ)' : '(c) Project Development Unit (PDU)' }}</b-td>
                                 <b-td>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.en')"
                                     v-model="development_activities.visiting_tea_garden.pdu_inspection_reason_en"
                                     ></b-form-input>
                                     <br>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.bn')"
                                     v-model="development_activities.visiting_tea_garden.pdu_inspection_reason_bn"
                                     ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.pdu_advice_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.pdu_advice_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.pdu_work_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.pdu_work_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.pdu_comment_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.pdu_comment_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(ঘ) অন্যান্য সরকারী দপ্তর' : '(d) Other Government Departments' }}</b-td>
                                 <b-td>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.en')"
                                     v-model="development_activities.visiting_tea_garden.ogd_inspection_reason_en"
                                     ></b-form-input>
                                     <br>
                                    <b-form-input
                                    :placeholder="$t('globalTrans.bn')"
                                     v-model="development_activities.visiting_tea_garden.ogd_inspection_reason_bn"
                                     ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.ogd_advice_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.ogd_advice_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.ogd_work_details_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.ogd_work_details_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.visiting_tea_garden.ogd_comment_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.visiting_tea_garden.ogd_comment_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.visiting_tea_garden.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.visiting_tea_garden.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৮. গবেষণা সম্পর্কিতঃ' : '8. Regarding research:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'চলমান গবেষণার বিষয় ও গবেষণার শিরোনাম' : 'Ongoing research topics and research titles' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'জমির পরিমাণ' : 'Amount of land' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'গবেষণার মেয়াদ' : 'Duration of research' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'গবেষণাটির বর্তমান অবস্থা' : 'Current status of research' }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(ক) বিটিআরআই কর্তৃক চলমান কোন গবেষণার পরীক্ষণ প্লট রয়েছে কিনা?' : '(a) Whether there is any ongoing research trial plot by BTRI?' }}</b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.research.btri_research_title_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.research.btri_research_title_bn"></b-form-input>
                                </b-td>
                                <b-td><b-form-input v-model="development_activities.research.btri_section_no"></b-form-input></b-td>
                                <b-td><b-form-input v-model="development_activities.research.btri_land_amount"></b-form-input></b-td>
                                <b-td><b-form-input v-model="development_activities.research.btri_duration"></b-form-input></b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.research.btri_current_status_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.research.btri_current_status_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(খ) অন্য কোন প্রতিষ্ঠান কর্তৃক পরিচালিত গবেষণার পরীক্ষণ প্লট রয়েছে কিনা?' : '(b) Whether there is a trial plot of research conducted by any other institution?' }}</b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.research.other_research_title_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.research.other_research_title_bn"></b-form-input>
                                </b-td>
                                <b-td><b-form-input v-model="development_activities.research.other_section_no"></b-form-input></b-td>
                                <b-td><b-form-input v-model="development_activities.research.other_land_amount"></b-form-input></b-td>
                                <b-td><b-form-input v-model="development_activities.research.other_duration"></b-form-input></b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.research.other_current_status_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.research.other_current_status_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.research.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.research.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৯. ভর্তুকি মূল্যের সার সংক্রান্তঃ' : '9. Regarding subsidized fertilizer:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? 'বাংলাদেশ চা বোর্ড কর্তৃক অনুমোদিত সারের পরিমাণ' : 'Amount of fertilizer approved by Bangladesh Tea Board' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'সার কারখানা/গুদাম হতে সংগৃহীত সারের পরিমাণ' : 'Amount of manure collected from the manure factory/warehouse' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'সার কারখানা/গুদাম হতে সংগৃহীত সার বাগানে আনয়নের তারিখ' : 'Date of introduction of manure collected from manure factory/warehouse to garden' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'চা বাগানে ব্যবহৃত সারের পরিমাণ' : 'Amount of fertilizer used in tea gardens' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'চা বাগানের গুদামে রক্ষিত অবশিষ্ট সারের পরিমাণ' : 'Amount of residual manure stored in tea garden warehouse' }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-form-input
                                     v-model="development_activities.subsidized_fertilizer.approved_fertilizer_amount"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input
                                     v-model="development_activities.subsidized_fertilizer.collected_fertilizer_amount"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                </b-td>
                                <b-td>
                                    <date-picker
                                        id="introduction_date"
                                        v-model="development_activities.subsidized_fertilizer.introduction_date"
                                        class="form-control"
                                        :placeholder="$t('globalTrans.select_date')"
                                    >
                                    </date-picker>
                                </b-td>
                                 <b-td>
                                    <b-form-input
                                     v-model="development_activities.subsidized_fertilizer.used_fertilizer_amount"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                </b-td>
                                 <b-td>
                                    <b-form-input
                                     v-model="development_activities.subsidized_fertilizer.residual_fertilizer_amount"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.subsidized_fertilizer.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.subsidized_fertilizer.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১০. চা বাগানে “পোকামাকড় ও রোগবালাই” সম্পর্কিতঃ' : '10. Regarding “Insects and Diseases” in Tea Gardens:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'বিগত ০৩ বছরে কি ধরনের “পোকামাকড় ও রোগবালাইয়ের আক্রমণ পরিলক্ষিত হয়েছে' : 'What kind of “insect and disease attack” has been observed in the last 03 years' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? '“পোকামাকড় ও রোগবালাই” দমনে ব্যবহুত “ইনসেক্টিসাইড” ও “পেস্টিসাইড” ঔষধের নাম এবং প্রস্তুতকারী প্রতিষ্ঠানের নাম' : 'Name of "Insecticide" and "Pesticide" drug used to control "Insects and Diseases" and name of manufacturer' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'ব্যবহৃত “ইনসেক্টিসাইড” ও “পেস্টিসাইডস” সমূহ বিটিআরআই কর্তৃক অনুমোদিত কিনা' : 'Whether the “Insecticides” and “Pesticides” used are approved by BTRI' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'হেক্টর/ একর প্রতি ব্যবহার “ইনসেক্টিসাইড” ও “পেস্টিসাইড” এর পরিমাণ' : 'Amount of “Insecticide” and “Pesticide” used per hectare/acre' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'ব্যবহৃত “ইনসেক্টিসাইড” ও “পেস্টিসাইড” এর কারণে উৎপাদনে প্রভাব' : 'Impact on production due to “insecticides” and “pesticides” used' }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(ক) পোকামাকড়' : '(a) Insects' }}</b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.insect_diseases.ins_kind_of_attack_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.insect_diseases.ins_kind_of_attack_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.insect_diseases.ins_drug_name_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.insect_diseases.ins_drug_name_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-radio-group v-model="development_activities.insect_diseases.ins_is_btri_approved">
                                        <b-form-radio value="1">{{$t('globalTrans.yes')}}</b-form-radio>
                                        <b-form-radio value="0">{{$t('globalTrans.no')}}</b-form-radio>
                                    </b-form-radio-group>
                                </b-td>
                                <b-td>
                                    <b-form-input
                                     v-model="development_activities.insect_diseases.ins_insecticide_pesticide_amount"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                     ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.insect_diseases.ins_production_impact_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.insect_diseases.ins_production_impact_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ currentLocale === 'bn' ? '(খ) রোগবালাই' : '(b) Disease' }}</b-td>
                                 <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.insect_diseases.dis_kind_of_attack_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.insect_diseases.dis_kind_of_attack_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.insect_diseases.dis_drug_name_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.insect_diseases.dis_drug_name_bn"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-radio-group v-model="development_activities.insect_diseases.dis_is_btri_approved">
                                        <b-form-radio value="1">{{$t('globalTrans.yes')}}</b-form-radio>
                                        <b-form-radio value="0">{{$t('globalTrans.no')}}</b-form-radio>
                                    </b-form-radio-group>
                                </b-td>
                                <b-td>
                                    <b-form-input
                                     v-model="development_activities.insect_diseases.dis_insecticide_pesticide_amount"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                     ></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input :placeholder="$t('globalTrans.en')" v-model="development_activities.insect_diseases.dis_production_impact_en"></b-form-input>
                                    <br>
                                    <b-form-input :placeholder="$t('globalTrans.bn')" v-model="development_activities.insect_diseases.dis_production_impact_bn"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                 <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.subsidized_fertilizer.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="development_activities.subsidized_fertilizer.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১১. চা বাগানে পোকামাকড় ও রোগবালাই সম্পর্কিত চার্ট সংরক্ষণ করা হয় কিনাঃ' : '11. Whether charts are maintained regarding pests and diseases in tea gardens:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                               <b-td>
                                      <b-form-radio-group v-model="development_activities.common_data.are_charts_maintained">
                                    <b-form-radio value="1">{{$t('globalTrans.yes')}}</b-form-radio>
                                    <b-form-radio value="0">{{$t('globalTrans.no')}}</b-form-radio>
                                </b-form-radio-group>
                               </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১২. চা বাগানের বিভিন্ন সেকশনাল কন্টুর প্রেন, কন্টুর পাথ ও পানি নিস্কাশ নের আউটলেট সম্পর্কিতঃ' : '12. Regarding various sectional contour lines, contour paths and drainage outlets of tea gardens:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-form-textarea :placeholder="$t('globalTrans.en')" v-model="development_activities.common_data.contour_drainage_info_en"></b-form-textarea>
                                    <br>
                                    <b-form-textarea :placeholder="$t('globalTrans.bn')" v-model="development_activities.common_data.contour_drainage_info_bn"></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১৩. চা বাগানের বিভিন্ন সকশনে আগাছা ও ও চা গাছের জন্য ক্ষতিকর অন্যান্য গাছের উপস্থিতি সম্পর্কিত বিবরণ এবং তা দমনে গুহীত ব্যবস্থাসমূহঃ' : '13. Details regarding the presence of weeds and other plants harmful to tea plants in different sections of the tea garden and the measures taken to control them:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                               <b-td>
                                    <b-form-textarea :placeholder="$t('globalTrans.en')" v-model="development_activities.common_data.harmful_info_en"></b-form-textarea>
                                    <br>
                                    <b-form-textarea :placeholder="$t('globalTrans.bn')" v-model="development_activities.common_data.harmful_info_bn"></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১৪. চা বাগানের সেকশনওয়ারী “Soli Map" করা আছে কিনা, থাকলে তার বিবরণঃ' : '14. Section wise “Soli Map” of tea plantations, details if yes:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-form-textarea :placeholder="$t('globalTrans.en')" v-model="development_activities.common_data.soil_map_details_en"></b-form-textarea>
                                    <br>
                                    <b-form-textarea :placeholder="$t('globalTrans.bn')" v-model="development_activities.common_data.soil_map_details_bn"></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১৫. চা বাগানের সেকশনওয়ারী “Soil Map” করা না থাকলে তা বাস্তবায়নের জন্য গৃহীত ব্যবস্থাসমূহঃ' : '15. Measures to be taken for implementation if section wise “Soil Map” of tea garden is not done:' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-form-textarea :placeholder="$t('globalTrans.en')" v-model="development_activities.common_data.measures_en"></b-form-textarea>
                                    <br>
                                    <b-form-textarea :placeholder="$t('globalTrans.bn')" v-model="development_activities.common_data.measures_bn"></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
            </div>
        </b-overlay>
    </b-form>
</ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { developmentActivityStoreUpdate } from '../../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
export default {
    props: ['loadingTab4', 'ins_gen_info_id', 'fiscalYearId', 'appDetailId', 'development_activities'],
    mixins: [ModalBaseMasterList],
    data () {
        return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getFiscalYear (yearId) {
         const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
                } else {
                return cateObj.text_en
                }
            }
        },
        // add more all type of tea
        addMoreNurseries (targeteArray) {
            targeteArray.push({
               nursery_id: '',
               caste_en: '',
               caste_bn: '',
               primary_germinated_seedlings: 0,
               secondary_germinated_seedlings: 0,
               plantable_seedlings: 0,
               total_seedlings: 0,
               collection_source_en: '',
               collection_source_bn: ''
            })
        },

        // remove all type of tea
        removeNurseries (targeteArray, index) {
            targeteArray.splice(index, 1)
        },

        // calculate nursery related data

           // total calculation all type of Land Green and Ready Tea
        calTotalNurseryData (data, type) {
            const totalPrimaryGerSeed = data.reduce((total, item) => total + parseFloat(item.primary_germinated_seedlings), 0)

            const totalSecondaryGerSeed = data.reduce((total, item) => total + parseFloat(item.secondary_germinated_seedlings), 0)

            const totalPlantSeed = data.reduce((total, item) => total + parseFloat(item.plantable_seedlings), 0)

            this.development_activities.nurseries.grand_total_seedlings = totalPrimaryGerSeed + totalSecondaryGerSeed + totalPlantSeed

            // for total primary seed
            if (type === 1) {
                this.development_activities.nurseries.total_primary_germinated_seedlings = totalPrimaryGerSeed.toFixed(2)
            }
            if (type === 2) {
                this.development_activities.nurseries.total_secondary_germinated_seedlings = totalSecondaryGerSeed.toFixed(2)
            }
            if (type === 3) {
                this.development_activities.nurseries.total_plantable_seedlings = totalPlantSeed.toFixed(2)
            }
        },

        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.loadingTab4 = true
                this.development_activities.common_data.inspection_id = this.ins_gen_info_id

                const result = await RestApi.postData(teaGardenServiceBaseUrl, developmentActivityStoreUpdate, this.development_activities)
                if (result.success) {
                    this.loadingTab4 = false
                    this.development_activities.save_update = 1
                    this.$toast.success({
                        title: 'Success',
                        message: this.development_activities.save_update ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
<style>
.vertical-middle table tr th,
.vertical-middle table tr td {
    vertical-align: middle;
}
</style>
