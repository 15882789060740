<template>
    <div class="evaluation-report pt-3">
        <div class="ev-tab-title p-2 mb-3 bg-light rounded">
          <i class="fas fa-leaf"></i>
          <h5 class="font-weight-bold ml-2">{{ currentLocale === 'bn' ? 'চা বাগানের পরিবীক্ষণ ও মূল্যায়ন প্রতিবেদন' : 'Inspection and Evaluation Report of Tea Plantations' }}</h5>
        </div>
        <b-card class="ev-main-tab" no-body>
        <b-tabs pills card align="center" v-model="tabIndex">
          <b-tab active>
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-ক' : 'Part-A' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(সাধারণ তথ্য)' : '(General Info)' }}</small>
              </div>
            </template>
            <!-- <pre>{{ins_gen_info}}</pre> -->
            <div class="tab-content">
              <tab1
                :key="key"
                :loadingTabOne="loading"
                :app_id="app_id"
                :appDetailId="appDetailId"
                :ins_gen_info="ins_gen_info"
                :gardenInfo="gardenInfo"
                :applicationDetail="applicationDetail"
                :userList="userList"
                ref="tab_1_form"
              />
            </div>
          </b-tab>
          <b-tab :disabled="isDisable1">
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-খ' : 'Part-B' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(পরিসংখ্যান তথ্য)' : '(Statistical Info)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab2
              :key="key"
              :loadingTab2="loading"
              :appDetailId="appDetailId"
              :app_id="app_id"
              :stats_id="stats_id"
              :visitingDate="ins_gen_info.visit_date"
              :from_tab_data="stats_info"
              :mainTabIndex="tabIndex"
              :isDisable2="isDisable2"
              @enable-tab-3="checkEnableTab3"
              @main-tab-index="checkFromSubTab"
              />
            </div>
          </b-tab>
          <b-tab :disabled="isDisable2">
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-গ' : 'Part-C' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(জমিতে চা রোপণের তথ্য)' : '(Tea Planting Info)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab3
              :key="key"
              :loadingTabThree="loading"
              :appDetailId="appDetailId"
              :ins_gen_info_id="app_id"
              :fiscalYearId="fiscalYearId"
              :tea_planting_info="tea_planting_info"
              ref="tab_3_form"
              />
            </div>
          </b-tab>
          <b-tab :disabled="isDisable3">
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-ঘ' : 'Part-D' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(উন্নয়ন কার্যক্রম)' : '(Development Activities)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab4
                :key="key"
                :loadingTab4="loading"
                :ins_gen_info_id="app_id"
                :fiscalYearId="fiscalYearId"
                :appDetailId="appDetailId"
                :development_activities="development_activities"
                ref="tab_4_form"
              />
            </div>
          </b-tab>
          <b-tab :disabled="isDisable4">
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-ঙ' : 'Part-E' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(চা উৎপাদন বিষয়ক)' : '(Tea Production)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab5
                :key="key"
                :loadingTab5="loading"
                :ins_gen_info_id="app_id"
                :fiscalYearId="fiscalYearId"
                :appDetailId="appDetailId"
                :visitingDate="ins_gen_info.visit_date"
                :tea_production_main="tea_production_main"
                ref="tab_5_form"
              />
            </div>
          </b-tab>
          <b-tab :disabled="isDisable5">
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-চ' : 'Part-F' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(ব্যবস্থাপনা বিষয়ক তথ্য)' : '(Management Information)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab6
                :key="key"
                :loadingTab6="loading"
                :ins_gen_info_id="app_id"
                :fiscalYearId="fiscalYearId"
                :appDetailId="appDetailId"
                :management_info="management_info"
                ref="tab_6_form"
              />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- tab control -->
      <div class="offset-md-4" v-if="tabIndex != 1">
        <b-button-group class="mt-2">
          <b-button v-if="!tabIndex == 0" @click="tabIndex--"><i class="ri-arrow-left-s-line"></i>{{ currentLocale === 'bn' ? 'পূর্ববর্তি ধাপ' : 'Previous Stap' }}</b-button>

          <b-button class="btn btn-warning" @click="saveAndNextOrDraft(2)">{{$t('globalTrans.save_draft')}}</b-button>

          <b-button v-if="tabIndex < 5" class="btn btn-success" @click="saveAndNextOrDraft(1)">{{ $t('globalTrans.draft_n_next') }} <i class="ri-arrow-right-s-line"></i>
          </b-button>

          <b-button @click="saveAndNextOrDraft(3)" class="btn btn-success" v-if="tabIndex == 5">{{ $t('globalTrans.finalSave')}}</b-button>
        </b-button-group>
      </div>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { evaluationReportAppInfo } from '../../../api/routes'
import tab1 from './tabs/tab1.vue'
import tab2 from './tabs/tab2.vue'
import tab3 from './tabs/tab3.vue'
import tab4 from './tabs/tab4.vue'
import tab5 from './tabs/tab5.vue'
import tab6 from './tabs/tab6.vue'

export default {
  components: {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6
  },
  data () {
    return {
      statsTabShow: false,
      tabIndex: 1,
      appDetailId: this.$route.params.id,
      applicationDetail: '',
      isDisable1: true,
      isDisable2: true,
      isDisable3: true,
      isDisable4: true,
      isDisable5: true,
      app_id: 0,
      key: 0,
      stats_id: 0,
      fiscalYearId: 0,
      garInsAppId: 0,
      userList: '',
      gardenInfo: '',
      loading: false,
      ins_gen_info: {
        delegate_name_en: '',
        delegate_name_bn: '',
        delegate_designation: '',
        delegate_email: '',
        delegate_mobile_no: '',
        garden_history: '',
        visit_date: new Date()
      },
      stats_info: {
        inspection_id: '',
        gov_leased_total_land_area: 0,
        gov_leased_immature_tea_plant: 0,
        gov_leased_new_tea_plant: 0,
        gov_leased_mature_tea_plant: 0,
        gov_leased_older_tea_plant: 0,
        gov_leased_very_old_tea_plant: 0,
        gov_leased_cultivation_land: 0,
        gov_leased_clone_nursery_place_en: '',
        gov_leased_seed_nursery_place_en: '',
        gov_leased_shade_nursery_place_en: '',
        gov_leased_seed_house_place_en: '',
        gov_leased_ncp_place_en: '',
        gov_leased_clone_nursery_place_bn: '',
        gov_leased_seed_nursery_place_bn: '',
        gov_leased_shade_nursery_place_bn: '',
        gov_leased_seed_house_place_bn: '',
        gov_leased_ncp_place_bn: '',
        gov_leased_clone_nursery: 0,
        gov_leased_seed_nursery: 0,
        gov_leased_shade_nursery: 0,
        gov_leased_seed_house: 0,
        gov_leased_ncp: 0,
        gov_leased_areas_dont_produce_tea: 0,
        gov_leased_total_cultivation_land: 0,
        gov_leased_tea_production: 0,
        gov_leased_soil_rehabilitation: 0,
        gov_leased_tea_plantation_abandoned: 0,
        gov_leased_non_tea_area: 0,
        gov_leased_rubber: 0,
        gov_leased_bamboo: 0,
        gov_leased_plated_trees: 0,
        gov_leased_natural_forest: 0,
        gov_leased_mats: 0,
        gov_leased_paddy_field: 0,
        gov_leased_jack_fruit_lemon: 0,
        gov_leased_other_fruits: 0,
        gov_leased_other_agricultural_land: 0,
        gov_leased_fallen_land: 0,
        gov_leased_water_bodies: 0,
        gov_leased_mosque: 0,
        gov_leased_grave: 0,
        gov_leased_labor_settlement: 0,
        gov_leased_factory: 0,
        gov_leased_school: 0,
        gov_leased_road: 0,
        gov_leased_non_agricultural_area: 0,
        gov_leased_total_sanctioned_leased_land: 0,
        gov_leased_plan_per_com_leased_land: 0,
        gov_leased_plan_per_com_suitable_land: 0,
        gov_leased_plan_hill_percentage: 0,
        gov_leased_plan_high_plains_percentage: 0,
        gov_leased_plan_plain_percentage: 0,
        individual_total_land_area: 0,
        individual_immature_tea_plant: 0,
        individual_new_tea_plant: 0,
        individual_mature_tea_plant: 0,
        individual_older_tea_plant: 0,
        individual_very_old_tea_plant: 0,
        individual_cultivation_land: 0,
        individual_clone_nursery_place_en: '',
        individual_seed_nursery_place_en: '',
        individual_shade_nursery_place_en: '',
        individual_seed_house_place_en: '',
        individual_ncp_place_en: '',
        individual_clone_nursery_place_bn: '',
        individual_seed_nursery_place_bn: '',
        individual_shade_nursery_place_bn: '',
        individual_seed_house_place_bn: '',
        individual_ncp_place_bn: '',
        individual_clone_nursery: 0,
        individual_seed_nursery: 0,
        individual_shade_nursery: 0,
        individual_seed_house: 0,
        individual_ncp: 0,
        individual_areas_dont_produce_tea: 0,
        individual_total_cultivation_land: 0,
        individual_tea_production: 0,
        individual_soil_rehabilitation: 0,
        individual_tea_plantation_abandoned: 0,
        individual_non_tea_area: 0,
        individual_rubber: 0,
        individual_bamboo: 0,
        individual_plated_trees: 0,
        individual_natural_forest: 0,
        individual_mats: 0,
        individual_paddy_field: 0,
        individual_jack_fruit_lemon: 0,
        individual_other_fruits: 0,
        individual_other_agricultural_land: 0,
        individual_fallen_land: 0,
        individual_water_bodies: 0,
        individual_mosque: 0,
        individual_grave: 0,
        individual_labor_settlement: 0,
        individual_factory: 0,
        individual_school: 0,
        individual_road: 0,
        individual_non_agricultural_area: 0,
        individual_total_sanctioned_leased_land: 0,
        individual_plan_per_com_leased_land: 0,
        individual_plan_per_com_suitable_land: 0,
        clone: 0,
        by_clone: 0,
        common_seed: 0,
        future_tea_plantation_land: 0,
        actual_cultivated_area: 0,
        compulsory_extension_plantation: 0,
        recommendation_non_implementation_en: '',
        recommendation_non_implementation_bn: '',
        case_description_en: '',
        case_description_nn: '',
        comment_en: '',
        comment_bn: '',
        factory_machine_comment_en: '',
        factory_machine_comment_bn: '',
        factory_new_machine_comment_en: '',
        factory_new_machine_comment_bn: '',
        factory_registration_no: '',
        is_new_factory_registration: '',
        tea_leave_sales_description_en: '',
        tea_leave_sales_description_bn: '',
        agricultural_machine_comment_en: '',
        agricultural_machine_comment_bn: '',
        development_plans_en: '',
        development_plans_bn: '',
        public_resource_comment_en: '',
        public_resource_comment_bn: '',
        labor_welfare_benefit_comment_en: '',
        labor_welfare_benefit_comment_bn: '',
        development_roadmap_comment_en: '',
        development_roadmap_comment_bn: ''
      },
      tea_planting_info: {
            save_update: 0,
            unripeTeaData: {
                total_replantation_acre: 0,
                total_extension_acre: 0,
                grand_total_area: 0,
                comment_en: '',
                comment_bn: '',
                details: []
            },
            newTeaData: {
                new_tea_tot_land_amount: 0,
                new_tea_tot_tea_prod_green_leaf: 0,
                new_tea_tot_tea_prod_ready_tea: 0,
                new_tea_comment_en: '',
                new_tea_comment_bn: '',
                newDetails: this.addAllTypeFiveData(1)
            },
            adultTeaData: {
                adult_tea_tot_land_amount: 0,
                adult_tea_tot_tea_prod_green_leaf: 0,
                adult_tea_tot_tea_prod_ready_tea: 0,
                adult_tea_comment_en: '',
                adult_tea_comment_bn: '',
                adultDetails: this.addAllTypeFiveData(2)
            },
            olderTeaData: {
                 older_tea_tot_land_amount: 0,
                 older_tea_tot_tea_prod_green_leaf: 0,
                 older_tea_tot_tea_prod_ready_tea: 0,
                 older_tea_comment_en: '',
                 older_tea_comment_bn: '',
                 olderDetails: this.addAllTypeFiveData(3)
            },
            oldestTeaData: {
                 oldest_tea_tot_land_amount: 0,
                 oldest_tea_tot_tea_prod_green_leaf: 0,
                 oldest_tea_tot_tea_prod_ready_tea: 0,
                 oldest_tea_comment_en: '',
                 oldest_tea_comment_bn: '',
                 oldestDetails: this.addAllTypeFiveData(4)
            },
            secWiseTeaData: {
                total_land_amount: 0,
                total_tea_leaves_amount: 0,
                total_tea_amount: 0,
                total_production: 0,
                comment_en: '',
                comment_bn: '',
                details: this.secWiseFiveData()
            }
      },
      development_activities: {
        save_update: 0,
        common_data: {
            inspection_id: 0,
            crop_plantation: '',
            loan_amount: 0,
            tripartite_agreement_copy: '',
            are_charts_maintained: 0,
            contour_drainage_info_en: '',
            contour_drainage_info_bn: '',
            harmful_info_en: '',
            harmful_info_bn: '',
            soil_map_details_en: '',
            soil_map_details_bn: '',
            measures_en: '',
            measures_bn: ''
        },
        development_infos: {
          fiscal_year_id: this.fiscalYearId,
          infilling_plan_no: '',
          section_no: '',
          land_clearing: 0,
          old_land_plant_production: 0,
          land_rehabilitation: 0,
          comment_en: '',
          comment_bn: ''
        },
        nurseries: {
          total_primary_germinated_seedlings: 0,
          total_secondary_germinated_seedlings: 0,
          total_plantable_seedlings: 0,
          grand_total_seedlings: 0,
          comment_en: '',
          comment_bn: '',
          details: this.nurseriesData()
        },
        rubber_plantations: {
          has_permission: 0,
          land_amount: 0,
          production_volume: 0,
          money_amount: 0,
          rubber_sale_place_en: '',
          rubber_sale_place_bn: '',
          comment_en: '',
          comment_bn: ''
        },
        forest_resources: {
          cutting_time_info_en: '',
          cutting_time_info_bn: '',
          has_permission: 0,
          permission_date: '',
          memo_no: '',
          forest_resource_amount: 0,
          money_amount: 0,
          development_work_details_en: '',
          development_work_details_bn: '',
          loan_amount: 0,
          comment_en: '',
          comment_bn: ''
        },
        visiting_tea_garden: {
          btb_inspection_reason_en: '',
          btb_inspection_reason_bn: '',
          btb_advice_details_en: '',
          btb_advice_details_bn: '',
          btb_work_details_en: '',
          btb_work_details_bn: '',
          btb_comment_en: '',
          btb_comment_bn: '',
          btri_inspection_reason_en: '',
          btri_inspection_reason_bn: '',
          btri_advice_details_en: '',
          btri_advice_details_bn: '',
          btri_work_details_en: '',
          btri_work_details_bn: '',
          btri_comment_en: '',
          btri_comment_bn: '',
          pdu_inspection_reason_en: '',
          pdu_inspection_reason_bn: '',
          pdu_advice_details_en: '',
          pdu_advice_details_bn: '',
          pdu_work_details_en: '',
          pdu_work_details_bn: '',
          pdu_comment_en: '',
          pdu_comment_bn: '',
          ogd_inspection_reason_en: '',
          ogd_inspection_reason_bn: '',
          ogd_advice_details_en: '',
          ogd_advice_details_bn: '',
          ogd_work_details_en: '',
          ogd_work_details_bn: '',
          ogd_comment_en: '',
          ogd_comment_bn: '',
          comment_en: '',
          comment_bn: ''
        },
        research: {
          btri_research_title_en: '',
          btri_research_title_bn: '',
          btri_section_no: '',
          btri_land_amount: '',
          btri_duration: '',
          btri_current_status_en: '',
          btri_current_status_bn: '',
          other_research_title_en: '',
          other_research_title_bn: '',
          other_section_no: '',
          other_land_amount: '',
          other_duration: '',
          other_current_status_en: '',
          other_current_status_bn: '',
          comment_en: '',
          comment_bn: ''
        },
        subsidized_fertilizer: {
           approved_fertilizer_amount: 0,
           collected_fertilizer_amount: 0,
           introduction_date: '',
           used_fertilizer_amount: 0,
           residual_fertilizer_amount: 0,
           comment_en: '',
           comment_bn: ''
        },
        insect_diseases: {
          ins_kind_of_attack_en: '',
          ins_kind_of_attack_bn: '',
          ins_drug_name_en: '',
          ins_drug_name_bn: '',
          ins_is_btri_approved: 0,
          ins_insecticide_pesticide_amount: 0,
          ins_production_impact_en: '',
          ins_production_impact_bn: '',
          dis_kind_of_attack_en: '',
          dis_kind_of_attack_bn: '',
          dis_drug_name_en: '',
          dis_drug_name_bn: '',
          dis_is_btri_approved: 0,
          dis_insecticide_pesticide_amount: 0,
          dis_production_impact_en: '',
          dis_production_impact_bn: '',
          comment_en: '',
          comment_bn: ''
        }
      },
      tea_production_main: {
        save_update: 0,
        productions: this.yearWiseProductionData(),
        tea_production_comment_en: '',
        tea_production_comment_bn: ''
      },
      management_info: {
         manager_name_en: '',
         manager_name_bn: '',
         manager_mobile: '',
         manager_email: '',
         deputy_manager_name_en: '',
         deputy_manager_name_bn: '',
         deputy_manager_mobile: '',
         deputy_manager_email: '',
         assistant_manager_name_en: '',
         assistant_manager_name_bn: '',
         assistant_manager_mobile: '',
         assistant_manager_email: '',
         field_employee_number: 0,
         factory_employee_number: 0,
         other_employee_number: 0,
         comment_en: '',
         comment_bn: '',
         actions_en: '',
         actions_bn: '',
         detailed_opinion_en: '',
         detailed_opinion_bn: ''
      }
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      const params = {
        appDetailId: this.appDetailId
      }
      const result = await RestApi.getData(teaGardenServiceBaseUrl, evaluationReportAppInfo, params)

      if (result.success) {
          this.key += 1
          const genInfo = result.data.applicationDetail?.ins_gen_info || this.ins_gen_info
          this.applicationDetail = result.data.applicationDetail
          this.gardenInfo = result.data.gardenInfo
          this.userList = result.data.userList
          this.fiscalYearId = result.data.applicationDetail?.application.fiscal_year_id
          this.garInsAppId = result.data.applicationDetail?.application.id

          this.ins_gen_info = genInfo
          this.app_id = genInfo.id
          if (genInfo.id) {
            this.isDisable1 = false
          }
          this.stats_id = genInfo.stats_info?.id
          if (this.stats_id) {
            this.stats_info = genInfo.stats_info
            this.isDisable1 = false
          }

          const teaPlaSecInfo = genInfo.tea_pla_sec_info || {}
          const devlopmentActivity = genInfo.development || {}

          if (Object.keys(teaPlaSecInfo).length > 0) {
            this.isDisable2 = false
            this.tea_planting_info = {
              save_update: 1,
              unripeTeaData: genInfo.tea_pla_unripe_tea,
              secWiseTeaData: teaPlaSecInfo,
              newTeaData: this.getFormatedData(genInfo.tea_pla_type, 1),
              adultTeaData: this.getFormatedData(genInfo.tea_pla_type, 2),
              olderTeaData: this.getFormatedData(genInfo.tea_pla_type, 3),
              oldestTeaData: this.getFormatedData(genInfo.tea_pla_type, 4)
            }
          }

          if (Object.keys(devlopmentActivity).length > 0) {
            this.isDisable3 = false
            this.development_activities = {
              save_update: 1,
              common_data: devlopmentActivity,
              development_infos: devlopmentActivity.info,
              nurseries: devlopmentActivity.nursery,
              rubber_plantations: devlopmentActivity.rubber_plantation,
              forest_resources: devlopmentActivity.forest_resource,
              visiting_tea_garden: devlopmentActivity.visiting_tea_garden,
              research: devlopmentActivity.research,
              subsidized_fertilizer: devlopmentActivity.subsidized_fertilizer,
              insect_diseases: devlopmentActivity.insect_diseas
            }
          }

          const teaProductions = genInfo.tea_productions || {}

          if (Object.keys(teaProductions).length > 0) {
             this.isDisable4 = false
            this.tea_production_main = {
              productions: teaProductions,
              tea_production_comment_en: genInfo.tea_production_comment_en,
              tea_production_comment_bn: genInfo.tea_production_comment_bn,
              save_update: 1
            }
          }

          const managementInfo = genInfo.management_info || {}

          if (Object.keys(managementInfo).length > 0) {
             this.isDisable5 = false
            this.management_info = { ...managementInfo, save_update: 1 }
          }

          this.loading = false
          this.statsTabShow = true
      } else {
        this.$toast.success({
          title: this.$t('globalTrans.error'),
          message: result.message,
          color: '#FF9494'
        })
        this.$router.go(-1)
      }
    },

    // here draft type 1 = save and next and type = 2 only draft and type = 3 is final save
    async saveAndNextOrDraft (type) {
        this.getTabDisableEnable()
        let formRef

      // Determine the form reference based on the current tabIndex
        switch (this.tabIndex) {
          case 0:
            formRef = this.$refs.tab_1_form
            break
          case 2:
            formRef = this.$refs.tab_3_form
            break
          case 3:
            formRef = this.$refs.tab_4_form
            break
          case 4:
            formRef = this.$refs.tab_5_form
            break
          case 5:
            formRef = this.$refs.tab_6_form
            break
          default:
            return
        }
          if (type === 3 || type === 2) {
            this.management_info.status = type === 3 ? 1 : 2
          }

          // Submit the form and handle success conditions
          const result = await formRef.submit()
          if (result.success) {
            if (this.tabIndex === 5) {
              if (type === 3) {
                this.$router.push({
                  name: 'tea_garden_service.pdu.prepare_garden_list',
                  params: { id: this.garInsAppId }
                })
              }
            } else if (type === 1) {
              if (this.tabIndex === 0) {
                this.app_id = result.app_id
              }
              this.tabIndex++
            }
          } else {
            this.isDisable1 = !this.isDisable1
          }
    },

    // tab enable disable
    getTabDisableEnable () {
      if (this.tabIndex === 0) {
        this.isDisable1 = false
      } else if (this.tabIndex === 1) {
        this.isDisable2 = false
      } else if (this.tabIndex === 2) {
        this.isDisable3 = false
      } else if (this.tabIndex === 3) {
        this.isDisable4 = false
      } else if (this.tabIndex === 4) {
        this.isDisable5 = false
      }
    },

      // all type of tea add five data in this function
        addAllTypeFiveData (type) {
            const dataObjects = []
            for (let i = 0; i < 5; i++) {
                const newDataObject = {
                    type: type,
                    plantation_year: '',
                    section_no: '',
                    land_amount: 0,
                    tea_variety_name_en: '',
                    tea_variety_name_bn: '',
                    tea_prod_green_leaf: 0,
                    tea_prod_ready_tea: 0
                }
                dataObjects.push(newDataObject)
            }
            return dataObjects
        },

         // section wise info tea garden
         secWiseFiveData () {
            const secDataObjects = []
            for (let i = 0; i < 5; i++) {
                const newSecDataObject = {
                    section_no: '',
                    soil_nature_percentage: '',
                    land_amount: 0,
                    tea_variety_name_en: '',
                    tea_variety_name_bn: '',
                    tea_leaves_amount: 0,
                    tea_amount: 0,
                    production_per_acre: 0,
                    production_percentage: 0
                }
                secDataObjects.push(newSecDataObject)
            }
            return secDataObjects
         },

         // development_activities nurseries data add
         nurseriesData () {
            const nurseriesDataObjects = []
            for (let i = 0; i < 5; i++) {
                const nurseriesDataObject = {
                    nursery_id: '',
                    caste_en: '',
                    caste_bn: '',
                    primary_germinated_seedlings: 0,
                    secondary_germinated_seedlings: 0,
                    plantable_seedlings: 0,
                    total_seedlings: 0,
                    collection_source_en: '',
                    collection_source_bn: ''
                }
                nurseriesDataObjects.push(nurseriesDataObject)
            }
            return nurseriesDataObjects
         },

         getFormatedData (data, type) {
            const teaTypeMapping = {
              1: { new: 'new', details: 'new_details', comment: 'new_tea_comment', landAmount: 'new_tea_tot_land_amount', greenLeaf: 'new_tea_tot_tea_prod_green_leaf', readyTea: 'new_tea_tot_tea_prod_ready_tea' },
              2: { new: 'adult', details: 'adult_details', comment: 'adult_tea_comment', landAmount: 'adult_tea_tot_land_amount', greenLeaf: 'adult_tea_tot_tea_prod_green_leaf', readyTea: 'adult_tea_tot_tea_prod_ready_tea' },
              3: { new: 'older', details: 'older_details', comment: 'older_tea_comment', landAmount: 'older_tea_tot_land_amount', greenLeaf: 'older_tea_tot_tea_prod_green_leaf', readyTea: 'older_tea_tot_tea_prod_ready_tea' },
              4: { new: 'oldest', details: 'oldest_details', comment: 'oldest_tea_comment', landAmount: 'oldest_tea_tot_land_amount', greenLeaf: 'oldest_tea_tot_tea_prod_green_leaf', readyTea: 'oldest_tea_tot_tea_prod_ready_tea' }
            }

            const mapping = teaTypeMapping[type]
            return {
              id: data.id,
              inspection_id: data.inspection_id,
              [`${mapping.new}Details`]: data[mapping.details],
              [`${mapping.new}_tea_comment_en`]: data[`${mapping.comment}_en`],
              [`${mapping.new}_tea_comment_bn`]: data[`${mapping.comment}_bn`],
              [`${mapping.new}_tea_tot_land_amount`]: data[mapping.landAmount],
              [`${mapping.new}_tea_tot_tea_prod_green_leaf`]: data[mapping.greenLeaf],
              [`${mapping.new}_tea_tot_tea_prod_ready_tea`]: data[mapping.readyTea]
            }
         },

        // for tea productions data
        yearWiseProductionData () {
            const dataObjects = []
            const currentYear = new Date().getFullYear()

            for (let i = 0; i < 5; i++) {
                const year = currentYear - i
                const newDataObject = {
                    production_year: year.toString(),
                    total_green_leaves: 0,
                    total_prod_tea_qty: 0,
                    tea_production_rate: 0,
                    warehouse_name_en: '',
                    warehouse_name_bn: '',
                    dispatched_tea_qty: 0,
                    broker_house_name_en: '',
                    broker_house_name_bn: '',
                    auction_sold_tea_qty: 0,
                    remaining_tea_qty: 0,
                    ex_garden_approved_qty: 0,
                    ex_garden_revenue_qty: 0,
                    unusable_tea_qty: 0,
                    average_price: 0,
                    average_production: 0,
                    production_cost: 0
                }
                dataObjects.push(newDataObject)
            }
               // Sort the data objects based on production_year in ascending order
                dataObjects.sort((a, b) => parseInt(a.production_year) - parseInt(b.production_year))
            return dataObjects
        },

      checkEnableTab3 (isEnable2) {
        this.isDisable2 = isEnable2
      },
      checkFromSubTab (mainTabIndex) {
        this.tabIndex = mainTabIndex
      }

  }
}
</script>
<style>
.evaluation-report .card.ev-main-tab{
  border: none;
  background: none;
}
.evaluation-report .card.ev-main-tab .tabs{
  border: none;
}
.evaluation-report .card.ev-main-tab .tabs .tab-content{
  background: #fff;
}
.evaluation-report .card.ev-main-tab .card-header{
  padding: 0 10px;
  background: #f5f5f5;
  border-bottom: none;
  box-shadow: 0 1px 6px rgba(0,0,0,.18);
}
.evaluation-report .card .card-header .nav-pills .nav-item{
  margin-right: 5px;
}
.evaluation-report .card .card-header .nav-pills .nav-link{
  color: var(--alt);
  border-radius: 0.25rem 0.25rem 0 0;
}
.evaluation-report .card .card-header .nav-pills .nav-link.active{
  position: relative;
  color: #fff;
  background-color: var(--alt);
}
.evaluation-report .card .card-header .nav-pills .nav-link.active::before{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--alt);
}
.evaluation-report .ev-tab-title{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.evaluation-report .ev-tab-title i{
  color: var(--success);
  font-size: 1.5rem;
  margin-bottom: 5px;
}
</style>
