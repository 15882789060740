<template>
<ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
        <b-overlay :show="loadingTabThree">
            <div>
                <section class="tab-part-3">
                    <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১. অপরিণত চা (১.৫ বছর)' : '1. Unripe tea (1.5 years)' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th rowspan="2">{{ currentLocale === 'bn' ? 'বৎসর' : 'Year' }}</b-th>
                                <b-th colspan="2">{{ currentLocale === 'bn' ? 'সম্প্রসারণ আবাদ' : 'Extension Plantation' }}</b-th>
                                <b-th colspan="2">{{ currentLocale === 'bn' ? 'খন্ড / পুন:আবাদ' : 'Section / Replantation' }}</b-th>
                                <b-th rowspan="2">{{ currentLocale === 'bn' ? 'মোট এলাকা (একর)' : 'Total Area (Acres)'}}</b-th>
                                <b-th rowspan="2">{{ currentLocale == 'bn' ? 'রোপিত জাত' : 'Planted varieties' }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'একর' : 'Acre' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'একর' : 'Acre' }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(unripeTea, index) in tea_planting_info.unripeTeaData.details" :key="index">
                                <b-td v-if="index == 0">{{ currentLocale == 'bn' ? '(১) চারা রোপণ বছর ' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")": '(1) Planting year ' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")" }}</b-td>
                                <b-td v-if="index == 1">
                                    {{ currentLocale == 'bn' ? '(২) রক্ষণাবেক্ষণ ১ম বছর' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")": '(2) Maintenance 1st Year ' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")" }}
                                </b-td>
                                <b-td v-if="index == 2">
                                    {{ currentLocale == 'bn' ? '(৩) রক্ষণাবেক্ষণ ২য় বছর' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")": '(3) Maintenance 2nd Year ' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")" }}
                                </b-td>
                                <b-td v-if="index == 3">
                                    {{ currentLocale == 'bn' ? '(৪) রক্ষণাবেক্ষণ ৩য় বছর' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")": '(5) Maintenance 3rd Year ' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")" }}
                                </b-td>
                                <b-td v-if="index == 4">
                                    {{ currentLocale == 'bn' ? '(৫) রক্ষণাবেক্ষণ ৪র্থ বছর' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")": '(5) Maintenance 4th Year ' + "(" +getFiscalYear(unripeTea.fiscal_year_id) + ")" }}
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="unripeTea.ext_section_no"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calUnripeteaTotal(tea_planting_info.unripeTeaData.details)" v-model="unripeTea.ext_acre"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="unripeTea.replan_section_no"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calUnripeteaTotal(tea_planting_info.unripeTeaData.details)" v-model="unripeTea.replan_acre"></b-form-input>
                                </b-td>
                                <b-td>
                                    {{parseFloat(unripeTea.ext_acre) + parseFloat(unripeTea.replan_acre)}}
                                </b-td>
                                <b-td style="width:15%;">
                                    <b-form-input v-model="unripeTea.tea_variety_name_en" :placeholder="$t('globalTrans.en')"></b-form-input>
                                    <br>
                                    <b-form-input v-model="unripeTea.tea_variety_name_bn" :placeholder="$t('globalTrans.bn')"></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr class="text-center">
                                <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{ $n(tea_planting_info.unripeTeaData.total_extension_acre) }}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{ $n(tea_planting_info.unripeTeaData.total_replantation_acre) }}</strong></b-td>
                                <b-td><strong>{{ $n(tea_planting_info.unripeTeaData.grand_total_area) }}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.unripeTeaData.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.unripeTeaData.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section class="tab-part-3">
                    <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '২. নতুন চা আবাদি (৬-১০ বছর)' : '2. New tea planters (6-10 years)' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                                <th>{{$t('globalTrans.action')}}</th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(newTea, index) in tea_planting_info.newTeaData.newDetails" :key="index">
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="newTea.plantation_year"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="newTea.section_no"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.newTeaData.newDetails,1)" v-model="newTea.land_amount"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="newTea.tea_variety_name_en" :placeholder="$t('globalTrans.en')"></b-form-input>
                                    <br>
                                     <b-form-input v-model="newTea.tea_variety_name_bn" :placeholder="$t('globalTrans.bn')"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.newTeaData.newDetails,1)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="newTea.tea_prod_green_leaf"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.newTeaData.newDetails,1)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="newTea.tea_prod_ready_tea"></b-form-input>
                                </b-td>
                                <b-td>
                                   <button
                                    v-if="index === tea_planting_info.newTeaData.newDetails.length - 1"
                                    @click="addMoreAllTypeTea(tea_planting_info.newTeaData.newDetails, 1)"
                                    class="btn btn-sm btn-success float-right my-2"
                                    type="button"
                                    ><i class="ri-add-fill"></i> {{$t('globalTrans.add_more')}}</button>
                                    <b-button v-if="index !== tea_planting_info.newTeaData.newDetails.length - 1 && index >= 4" class="btn btn-danger" size="sm" @click="removeTeaPlanter(tea_planting_info.newTeaData.newDetails, index)" :title="$t('globalTrans.delete')">
                                    <i class="ri-close-line"></i></b-button>
                                </b-td>
                            </b-tr>
                            <b-tr class="text-center">
                                <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.newTeaData.new_tea_tot_land_amount)}}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.newTeaData.new_tea_tot_tea_prod_green_leaf)}}</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.newTeaData.new_tea_tot_tea_prod_ready_tea)}}</strong></b-td>
                                <b-td></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                    <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.newTeaData.new_tea_comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.newTeaData.new_tea_comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section class="tab-part-3">
                    <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৩. পূর্ণ বয়স্ক চা (১১-৪০ বৎসর)' : '3. Adult tea (11-40 years)' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                                <b-th>{{$t('globalTrans.action')}}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(adultPlanter, index) in tea_planting_info.adultTeaData.adultDetails" :key="index">
                               <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="adultPlanter.plantation_year"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="adultPlanter.section_no"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.adultTeaData.adultDetails,2)" v-model="adultPlanter.land_amount"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="adultPlanter.tea_variety_name_en" :placeholder="$t('globalTrans.en')"></b-form-input>
                                    <br>
                                     <b-form-input v-model="adultPlanter.tea_variety_name_bn" :placeholder="$t('globalTrans.bn')"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.adultTeaData.adultDetails,2)" v-model="adultPlanter.tea_prod_green_leaf"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.adultTeaData.adultDetails,2)" v-model="adultPlanter.tea_prod_ready_tea"></b-form-input>
                                </b-td>
                                <b-td>
                                   <button
                                    v-if="index === tea_planting_info.adultTeaData.adultDetails.length - 1"
                                    @click="addMoreAllTypeTea(tea_planting_info.adultTeaData.adultDetails, 2)"
                                    class="btn btn-sm btn-success float-right my-2"
                                    type="button"
                                    ><i class="ri-add-fill"></i> {{$t('globalTrans.add_more')}}</button>
                                    <b-button v-if="index !== tea_planting_info.adultTeaData.adultDetails.length - 1 && index >= 4" class="btn btn-danger" size="sm" @click="removeTeaPlanter(tea_planting_info.adultTeaData.adultDetails, index)" :title="$t('globalTrans.delete')">
                                    <i class="ri-close-line"></i></b-button>
                                </b-td>
                            </b-tr>
                            <b-tr class="text-center">
                               <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.adultTeaData.adult_tea_tot_land_amount)}}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.adultTeaData.adult_tea_tot_tea_prod_green_leaf)}}</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.adultTeaData.adult_tea_tot_tea_prod_ready_tea)}}</strong></b-td>
                                <b-td></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                    <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.adultTeaData.adult_tea_comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.adultTeaData.adult_tea_comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section class="tab-part-3">
                    <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৪. বয়স্ক চা (৪১-৬০ বৎসর)' : '4. Elderly tea (41-60 years)' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                                 <b-th>{{$t('globalTrans.action')}}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(older, index) in tea_planting_info.olderTeaData.olderDetails" :key="index">
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="older.plantation_year"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="older.section_no"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.olderTeaData.olderDetails,3)" v-model="older.land_amount"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="older.tea_variety_name_en" :placeholder="$t('globalTrans.en')"></b-form-input>
                                    <br>
                                     <b-form-input v-model="older.tea_variety_name_bn" :placeholder="$t('globalTrans.bn')"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.olderTeaData.olderDetails,3)" v-model="older.tea_prod_green_leaf"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.olderTeaData.olderDetails,3)" v-model="older.tea_prod_ready_tea"></b-form-input>
                                </b-td>
                                <b-td>
                                   <button
                                    v-if="index === tea_planting_info.olderTeaData.olderDetails.length - 1"
                                    @click="addMoreAllTypeTea(tea_planting_info.olderTeaData.olderDetails, 3)"
                                    class="btn btn-sm btn-success float-right my-2"
                                    type="button"
                                    ><i class="ri-add-fill"></i> {{$t('globalTrans.add_more')}}</button>
                                    <b-button v-if="index !== tea_planting_info.olderTeaData.olderDetails.length - 1 && index >= 4" class="btn btn-danger" size="sm" @click="removeTeaPlanter(tea_planting_info.olderTeaData.olderDetails, index)" :title="$t('globalTrans.delete')">
                                    <i class="ri-close-line"></i></b-button>
                                </b-td>
                            </b-tr>
                            <b-tr class="text-center">
                               <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.olderTeaData.older_tea_tot_land_amount)}}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.olderTeaData.older_tea_tot_tea_prod_green_leaf)}}</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.olderTeaData.older_tea_tot_tea_prod_ready_tea)}}</strong></b-td>
                                <b-td></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                     <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.olderTeaData.older_tea_comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.olderTeaData.older_tea_comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section class="tab-part-3">
                    <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৫. অতি বয়স্ক চা (৬০ বছরের উর্ধে)' : '5. Very old tea (above 60 years)' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section no' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                                 <th>{{$t('globalTrans.action')}}</th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                           <b-tr v-for="(oldest, index) in tea_planting_info.oldestTeaData.oldestDetails" :key="index">
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="oldest.plantation_year"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="oldest.section_no"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.oldestTeaData.oldestDetails,4)" v-model="oldest.land_amount"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="oldest.tea_variety_name_en" :placeholder="$t('globalTrans.en')"></b-form-input>
                                    <br>
                                    <b-form-input v-model="oldest.tea_variety_name_bn" :placeholder="$t('globalTrans.bn')"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.oldestTeaData.oldestDetails,4)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="oldest.tea_prod_green_leaf"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calTotalAmountLandGreenReadyTea(tea_planting_info.oldestTeaData.oldestDetails,4)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="oldest.tea_prod_ready_tea"></b-form-input>
                                </b-td>
                                <b-td>
                                   <button
                                    v-if="index === tea_planting_info.oldestTeaData.oldestDetails.length - 1"
                                    @click="addMoreAllTypeTea(tea_planting_info.oldestTeaData.oldestDetails, 4)"
                                    class="btn btn-sm btn-success float-right my-2"
                                    type="button"
                                    ><i class="ri-add-fill"></i> {{$t('globalTrans.add_more')}}</button>
                                    <b-button v-if="index !== tea_planting_info.oldestTeaData.oldestDetails.length - 1 && index >= 4" class="btn btn-danger" size="sm" @click="removeTeaPlanter(tea_planting_info.oldestTeaData.oldestDetails, index)" :title="$t('globalTrans.delete')">
                                    <i class="ri-close-line"></i></b-button>
                                </b-td>
                            </b-tr>
                            <b-tr class="text-center">
                               <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.oldestTeaData.oldest_tea_tot_land_amount)}}</strong></b-td>
                                <b-td><strong>-</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.oldestTeaData.oldest_tea_tot_tea_prod_green_leaf)}}</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.oldestTeaData.oldest_tea_tot_tea_prod_ready_tea)}}</strong></b-td>
                                <b-td></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                  <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.oldestTeaData.oldest_tea_comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.oldestTeaData.oldest_tea_comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section class="tab-part-3">
                    <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৬. চা বাগানের সেকশন ভিত্তিক তথ্যাদিঃ' : '6. Section wise information on tea gardens' }}</h6>
                    <b-table-simple responsive bordered>
                        <b-thead class="text-center">
                            <b-tr>
                                <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section no' }}</b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'ভূমি প্রকৃতির পরিমাণ ও শতকরা হার %' : 'Amount and percentage of soil nature %' }} <small class="text-muted d-block">{{ currentLocale == 'bn' ? 'টিলা/উচ্চ সমভূমি/ সমভূমি' : 'Hills/high plains/ plains' }}</small></b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ' : 'Amount of land' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(একর)' : '' }}</small></b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'উৎপাদিত কাঁচা চা পাতার পরিমাণ' : 'Amount of raw tea leaves produced' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(কেজি)' : '(KG)' }}</small></b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'উৎপাদিত তৈরি চায়ের পরিমাণ' : 'Amount of tea produced' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(কেজি)' : '(KG)' }}</small></b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'একর প্রতি উৎপাদন' : 'Production per acre' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(কেজি)' : '(KG)' }}</small></b-th>
                                <b-th>{{ currentLocale == 'bn' ? 'উৎপাদনের শতকরা হার' : 'Percentage of production' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(%)' : '(%)' }}</small></b-th>
                                <b-th>{{$t('globalTrans.action')}}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                             <b-tr v-for="(secWiseTea, index) in tea_planting_info.secWiseTeaData.details" :key="index">
                                <b-td>
                                    <b-form-input v-model="secWiseTea.section_no"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="secWiseTea.soil_nature_percentage"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calSecWiseTotal(tea_planting_info.secWiseTeaData.details, 1)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="secWiseTea.land_amount"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="secWiseTea.tea_variety_name_en" :placeholder="$t('globalTrans.en')"></b-form-input>
                                    <br>
                                    <b-form-input v-model="secWiseTea.tea_variety_name_bn" :placeholder="$t('globalTrans.bn')"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calSecWiseTotal(tea_planting_info.secWiseTeaData.details, 2)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="secWiseTea.tea_leaves_amount"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calSecWiseTotal(tea_planting_info.secWiseTeaData.details, 3)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="secWiseTea.tea_amount"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input @keyup="calSecWiseTotal(tea_planting_info.secWiseTeaData.details, 4)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  v-model="secWiseTea.production_per_acre"></b-form-input>
                                </b-td>
                                <b-td>
                                    <b-form-input v-model="secWiseTea.production_percentage"></b-form-input>
                                </b-td>
                                <b-td>
                                   <button
                                    v-if="index === tea_planting_info.secWiseTeaData.details.length - 1"
                                    @click="addMoreSectionWise(tea_planting_info.secWiseTeaData.details)"
                                    class="btn btn-sm btn-success float-right my-2"
                                    type="button"
                                    ><i class="ri-add-fill"></i> {{$t('globalTrans.add_more')}}</button>
                                    <b-button v-if="index !== tea_planting_info.secWiseTeaData.details.length - 1 && index >= 4" class="btn btn-danger" size="sm" @click="removeTeaPlanter(tea_planting_info.secWiseTeaData.details, index)" :title="$t('globalTrans.delete')">
                                    <i class="ri-close-line"></i></b-button>
                                </b-td>
                            </b-tr>
                            <b-tr class="text-center">
                                <b-td colspan="2"><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.secWiseTeaData.total_land_amount)}}</strong></b-td>
                                <b-td><strong></strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.secWiseTeaData.total_tea_leaves_amount)}}</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.secWiseTeaData.total_tea_amount)}}</strong></b-td>
                                <b-td><strong>{{$n(tea_planting_info.secWiseTeaData.total_production)}}</strong></b-td>
                                <b-td><strong></strong></b-td>
                                <b-td></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="10">
                                    <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                                    <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.secWiseTeaData.comment_en"
                                    :placeholder="$t('globalTrans.en')"
                                    ></b-form-textarea>
                                    <br>
                                   <b-form-textarea
                                    id="reason"
                                    v-model="tea_planting_info.secWiseTeaData.comment_bn"
                                    :placeholder="$t('globalTrans.bn')"
                                    ></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
            </div>
        </b-overlay>
    </b-form>
</ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { teaPlantingStoreUpdate } from '../../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
export default {
     props: ['loadingTabThree', 'appDetailId', 'ins_gen_info_id', 'fiscalYearId', 'tea_planting_info'],
     mixins: [ModalBaseMasterList],
    data () {
        return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
            app_id: 0
        }
    },
    created () {
        if (this.tea_planting_info.save_update === 0) {
            this.tea_planting_info.unripeTeaData.details = this.fiscalYearList
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        cloneNameList () {
         return this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1)
        },
         fiscalYearList () {
        // Get the entire fiscalYearList from the store
        const fullList = this.$store.state.CommonService.commonObj.fiscalYearList
        // Find the index of the fiscal year with the specified ID
        const targetYearId = this.fiscalYearId
        const targetYearIndex = fullList.findIndex((year) => year.value === targetYearId)
        if (targetYearIndex !== -1) {
            // Get the previous five fiscal years, including the one with the specified ID
            const startIndex = Math.max(targetYearIndex - 4, 0)
            const lastFiveYears = fullList.slice(startIndex, targetYearIndex + 1)
            // Return the filtered list
            const sortingYear = lastFiveYears.sort((a, b) => b.value - a.value)
            const mapingSortingYear = sortingYear.map(year => {
                return {
                    fiscal_year_id: year.value,
                    ext_section_no: '',
                    ext_acre: 0,
                    replan_section_no: '',
                    replan_acre: 0,
                    total_area: '',
                    tea_variety_name_en: '',
                    tea_variety_name_bn: ''
                }
            })
            return mapingSortingYear
        } else {
            return []
        }
    }
    },
    methods: {
        getFiscalYear (yearId) {
         const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
                } else {
                return cateObj.text_en
                }
            }
        },
        // add more all type of tea
        addMoreAllTypeTea (targeteArray, type) {
            targeteArray.push({
                type: type,
                plantation_year: '',
                section_no: '',
                land_amount: 0,
                tea_variety_name_en: '',
                tea_variety_name_bn: '',
                tea_prod_green_leaf: 0,
                tea_prod_ready_tea: 0
            })
        },

        // add more section wise tea
        addMoreSectionWise (targeteArray) {
            targeteArray.push({
                section_no: '',
                soil_nature_percentage: '',
                land_amount: 0,
                tea_variety_name_en: '',
                tea_variety_name_bn: '',
                tea_leaves_amount: 0,
                tea_amount: 0,
                production_per_acre: 0,
                production_percentage: 0
            })
        },

        // remove all type of tea
        removeTeaPlanter (targeteArray, index) {
            targeteArray.splice(index, 1)
        },

        // unripe total calculation
        calUnripeteaTotal (data) {
            const totalAcreRepPlan = data.reduce((total, item) => total + parseFloat(item.replan_acre, 10), 0)
            this.tea_planting_info.unripeTeaData.total_replantation_acre = totalAcreRepPlan.toFixed(2)

            const totalAcreExtPlan = data.reduce((total, item) => total + parseFloat(item.ext_acre, 10), 0)
            this.tea_planting_info.unripeTeaData.total_extension_acre = totalAcreExtPlan.toFixed(2)

            const unripeTotalAcre = parseFloat(this.tea_planting_info.unripeTeaData.total_replantation_acre) + parseFloat(this.tea_planting_info.unripeTeaData.total_extension_acre)
            this.tea_planting_info.unripeTeaData.grand_total_area = unripeTotalAcre.toFixed(2)
        },

        // section wise tea data total calculation
        calSecWiseTotal (data, type) {
            if (type === 1) {
                const totalLand = data.reduce((total, item) => total + parseFloat(item.land_amount, 10), 0)
                this.tea_planting_info.secWiseTeaData.total_land_amount = totalLand.toFixed(2)
            }

            if (type === 2) {
                const teaLeavesAmount = data.reduce((total, item) => total + parseFloat(item.tea_leaves_amount, 10), 0)
                this.tea_planting_info.secWiseTeaData.total_tea_leaves_amount = teaLeavesAmount.toFixed(2)
            }

            if (type === 3) {
              const teaAmount = data.reduce((total, item) => total + parseFloat(item.tea_amount, 10), 0)
             this.tea_planting_info.secWiseTeaData.total_tea_amount = teaAmount.toFixed(2)
            }

            if (type === 4) {
               const totalProduction = data.reduce((total, item) => total + parseFloat(item.production_per_acre, 10), 0)
                this.tea_planting_info.secWiseTeaData.total_production = totalProduction.toFixed(2)
            }
        },

        // total calculation all type of Land Green and Ready Tea
        calTotalAmountLandGreenReadyTea (data, type) {
            const totalLandAcre = data.reduce((total, item) => total + parseFloat(item.land_amount), 0)

            const totalProdGreenLeaf = data.reduce((total, item) => total + parseFloat(item.tea_prod_green_leaf), 0)

            const totalProdReadyTea = data.reduce((total, item) => total + parseFloat(item.tea_prod_ready_tea), 0)

            // for new tea
            if (type === 1) {
                this.tea_planting_info.newTeaData.new_tea_tot_land_amount = totalLandAcre.toFixed(2)
                this.tea_planting_info.newTeaData.new_tea_tot_tea_prod_green_leaf = totalProdGreenLeaf.toFixed(2)
                this.tea_planting_info.newTeaData.new_tea_tot_tea_prod_ready_tea = totalProdReadyTea.toFixed(2)
            }
            // for adult tea
            if (type === 2) {
                this.tea_planting_info.adultTeaData.adult_tea_tot_land_amount = totalLandAcre.toFixed(2)
                this.tea_planting_info.adultTeaData.adult_tea_tot_tea_prod_green_leaf = totalProdGreenLeaf.toFixed(2)
                this.tea_planting_info.adultTeaData.adult_tea_tot_tea_prod_ready_tea = totalProdReadyTea.toFixed(2)
            }
            // for older tea
            if (type === 3) {
                this.tea_planting_info.olderTeaData.older_tea_tot_land_amount = totalLandAcre.toFixed(2)
                this.tea_planting_info.olderTeaData.older_tea_tot_tea_prod_green_leaf = totalProdGreenLeaf.toFixed(2)
                this.tea_planting_info.olderTeaData.older_tea_tot_tea_prod_ready_tea = totalProdReadyTea.toFixed(2)
            }
            // for oldest tea
            if (type === 4) {
                this.tea_planting_info.oldestTeaData.oldest_tea_tot_land_amount = totalLandAcre.toFixed(2)
                this.tea_planting_info.oldestTeaData.oldest_tea_tot_tea_prod_green_leaf = totalProdGreenLeaf.toFixed(2)
                this.tea_planting_info.oldestTeaData.oldest_tea_tot_tea_prod_ready_tea = totalProdReadyTea.toFixed(2)
            }
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.loadingTabThree = true

                this.tea_planting_info.ins_gen_info_id = this.ins_gen_info_id
                this.tea_planting_info.appDetailId = this.appDetailId

                const result = await RestApi.postData(teaGardenServiceBaseUrl, teaPlantingStoreUpdate, this.tea_planting_info)
                if (result.success) {
                    this.loadingTabThree = false
                    this.tea_planting_info.save_update = 1
                    this.$toast.success({
                        title: 'Success',
                        message: this.tea_planting_info.save_update ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
<style>
.tab-part-3 table tr th,
.tab-part-3 table tr td {
    vertical-align: middle;
}
</style>
