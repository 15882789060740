<template>
    <b-overlay :show="loadingTab2">
      <div class="ev-tab-elements-tab-two">
          <b-tabs pills card align="center" v-model="subTabIndex">
              <b-tab :title="currentLocale === 'bn' ? 'প্রথম খন্ড' : 'First Part'" active>
                  <div class="tab-content">
                      <tab1
                       :loadingSubTab1="loadingTab2"
                       :main_tab_data="from_tab_data"
                       :app_id="app_id"
                       :key="1"
                        ref="stats_part_one"
                        @stats-id-set="statsIdSet"
                       />
                  </div>
              </b-tab>
              <b-tab :title="currentLocale === 'bn' ? 'দ্বিতিয় খন্ড' : 'Second Part'" :disabled="isSubDisable1">
                  <div class="tab-content">
                      <tab2
                         :loadingSubTab2="loadingTab2"
                         :main_tab_data="from_tab_data"
                         :app_id="app_id"
                         :stats_id="stats_id"
                         :key="2"
                         ref="stats_part_two"
                      />
                  </div>
              </b-tab>
             <b-tab :title="currentLocale === 'bn' ? 'তৃতীয় খন্ড' : 'Third Part'" :disabled="isSubDisable2">
                  <div class="tab-content">
                      <tab3
                        :loadingSubTab3="loadingTab2"
                        :main_tab_data="from_tab_data"
                        :app_id="app_id"
                        :stats_id="stats_id"
                        :key="3"
                        ref="stats_part_three"
                       />
                  </div>
              </b-tab>
             <b-tab :title="currentLocale === 'bn' ? 'চতুর্থ খন্ড' : 'Fourth Part'" :disabled="isSubDisable3">
                  <div class="tab-content">
                      <tab4
                        :loadingSubTab4="loadingTab2"
                        :main_tab_data="from_tab_data"
                        :app_id="app_id"
                        :stats_id="stats_id"
                        :key="5"
                        ref="stats_part_four"
                      />
                  </div>
              </b-tab>
              <b-tab :title="currentLocale === 'bn' ? 'পঞ্চম খন্ড' : 'Fifth Part'" :disabled="isSubDisable4">
                  <div class="tab-content">
                      <tab5 ref="stats_part_five" :loadingSubTab5="loadingTab2" :main_tab_data="from_tab_data" :visitingDate="visitingDate" :app_id="app_id" :stats_id="stats_id" :key="5"/>
                  </div>
              </b-tab>
          </b-tabs>
          <div class="offset-md-4">
              <b-button-group class="mt-2">
                  <b-button v-if="!subTabIndex == 0" @click="subTabIndex--">
                      <i class="ri-arrow-left-s-line"></i>{{ currentLocale === 'bn' ? 'পূর্ববর্তি ধাপ' : 'Previous Stap' }}
                  </b-button>

                  <b-button class="btn btn-warning" @click="saveAndNext(1)">
                      {{$t('globalTrans.save_draft')}}
                  </b-button>

                  <b-button class="btn btn-success" @click="saveAndNext(2)">
                      {{ $t('globalTrans.draft_n_next') }} <i class="ri-arrow-right-s-line"></i>
                  </b-button>
              </b-button-group>
          </div>
          <div>
        </div>
      </div>
    </b-overlay>
</template>
<script>
import tab1 from './sub-tabs-for-tab-2/tab1.vue'
import tab2 from './sub-tabs-for-tab-2/tab2.vue'
import tab3 from './sub-tabs-for-tab-2/tab3.vue'
import tab4 from './sub-tabs-for-tab-2/tab4.vue'
import tab5 from './sub-tabs-for-tab-2/tab5.vue'
export default {
  props: ['loadingTab2', 'app_id', 'appDetailId', 'from_tab_data', 'stats_id', 'visitingDate', 'mainTabIndex', 'isDisable2'],
  data () {
    return {
      subTabIndex: 1,
      isSubDisable1: true,
      isSubDisable2: true,
      isSubDisable3: true,
      isSubDisable4: true,
      tab_one: {
    }
  }
  },
  components: {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  created () {
    if (this.from_tab_data.factory_machinery?.length > 0) {
      this.isSubDisable1 = false
    }
    if (this.from_tab_data.agriculture_machinery?.length > 0) {
      this.isSubDisable2 = false
    }
    if (this.from_tab_data.lab_wel_benefit?.length > 0) {
      this.isSubDisable3 = false
    }
    if (this.from_tab_data?.dev_road_maps?.id) {
      this.isSubDisable4 = false
    }
  },
  methods: {
    async saveAndNext (type) {
      this.getTabDisableEnable()
      if (this.subTabIndex === 4) {
         this.isDisable2 = false
         this.$emit('enable-tab-3', this.isDisable2)
      }
      const tabComponents = [
        this.$refs.stats_part_one,
        this.$refs.stats_part_two,
        this.$refs.stats_part_three,
        this.$refs.stats_part_four,
        this.$refs.stats_part_five
      ]

      if (this.subTabIndex >= 0 && this.subTabIndex < tabComponents.length) {
        const result = await tabComponents[this.subTabIndex].saveUpdate()
        if (result.success) {
          if (type === 1) {
          } else {
            if (this.subTabIndex < 4) {
              this.subTabIndex++
            } else {
              this.mainTabIndex++
              this.$emit('main-tab-index', this.mainTabIndex)
            }
          }
        }
      }
    },
    getTabDisableEnable () {
      if (this.subTabIndex === 0) {
        this.isSubDisable1 = false
      } else if (this.subTabIndex === 1) {
        this.isSubDisable2 = false
      } else if (this.subTabIndex === 2) {
        this.isSubDisable3 = false
      } else if (this.subTabIndex === 3) {
        this.isSubDisable4 = false
      }
    },
    statsIdSet (statId) {
      this.stats_id = statId
    }
  }
}
</script>
<style>
.ev-tab-elements-tab-two .tabs .tab-content{
    padding: 0;
}
.ev-tab-elements-tab-two .tabs .card-header .nav-pills .nav-item{
    margin-right: 0!important;
    width: 20%;
    text-align: center;
}
.ev-tab-elements-tab-two .tabs .card-header .nav-pills .nav-item .nav-link{
    border-radius: 0;
}
.ev-tab-elements-tab-two .tabs .tab-content .card-body{
    padding: 10px 0;
}
</style>
