<template>
  <div>
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
          <b-overlay :show="loadingTabOne">
            <div class="ev-tab-elements">
                <b-table-simple responsive bordered>
                  <b-tbody>
                    <b-tr>
                      <b-td>{{ $n(1) }}</b-td>
                      <b-td style="width:50%;">{{ $t('teaGardenPduService.tea_garden_name_address') }}</b-td>
                      <b-td>{{currentLocal == 'bn' ? gardenInfo.tea_garden_name_bn + ', ' + gardenInfo.registration_no_en + ', ' + gardenInfo.garden_class_bn :  gardenInfo.tea_garden_name_en + ', ' + gardenInfo.registration_no_en + ', ' + gardenInfo.garden_class_en}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $n(2) }}</b-td>
                      <b-td>{{ $t('teaGardenPduService.tea_garden_office_info') }} <small class="text-muted">{{ $t('teaGardenPduService.icluding_mob_email') }}</small></b-td>
                      <b-td>{{ AddressHelper.getCommonAddress(gardenInfo)}}, {{currentLocal == 'en' ? gardenInfo.garden_address_en : gardenInfo.garden_address_bn }}, {{$t('globalTrans.mobile') + ': ' + $n(gardenInfo.mobile, {useGrouping:false}) + ', '+ $t('globalTrans.email') + ': ' + gardenInfo.email}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $n(3) }}</b-td>
                      <b-td>{{ $t('teaGardenPduService.garden_authority_info') }} <small class="text-muted">{{ $t('teaGardenPduService.icluding_mob_email') }}</small></b-td>
                      <b-td>{{currentLocal == 'en' ? gardenInfo.owner_name_en : gardenInfo.owner_name_bn }}, {{$t('globalTrans.mobile') + ': ' + $n(gardenInfo.mobile, {useGrouping:false}) + ', '+ $t('globalTrans.email') + ': ' + gardenInfo.email}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $n(4) }}</b-td>
                      <b-td>{{ $t('teaGardenPduService.garden_inspactor_info') }} <small class="text-muted">{{ $t('teaGardenPduService.icluding_mob_email') }}</small></b-td>
                      <b-td>
                        <p v-for="(user, index) in userList" :key="index">
                          {{$n(index+1) + '.' }} {{currentLocal == 'bn' ? user.text_bn : user.text_en}}, {{getDesignationName(user.designation_id)}}, {{$t('globalTrans.mobile') + ': ' + $n(user.mobile, {useGrouping:false}) + ', '+ $t('globalTrans.email') + ': ' + user.email}}
                        </p>
                        {{$t('globalTrans.date')}} : {{ ins_gen_info.visit_date | dateFormat}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td rowspan="4">{{ $n(5) }}</b-td>
                      <b-td>{{ $t('teaGardenPduService.representative_name')}}</b-td>
                      <b-td>
                        <b-form-input
                        v-model="ins_gen_info.delegate_name_en"
                        :placeholder="$t('globalTrans.name') +' '+ $t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                        v-model="ins_gen_info.delegate_name_bn"
                        :placeholder="$t('globalTrans.name')  +' '+ $t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('teaGardenPduService.representative_designation')}}</b-td>
                      <b-td>
                        <b-form-input
                        v-model="ins_gen_info.delegate_designation_en"
                        :placeholder="$t('globalTrans.designation') +' '+ $t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                        v-model="ins_gen_info.delegate_designation_bn"
                        :placeholder="$t('globalTrans.designation')  +' '+ $t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('teaGardenPduService.representative_mobile') }}</b-td>
                      <b-td>
                        <b-form-input v-model="ins_gen_info.delegate_mobile_no" type="number"></b-form-input>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('teaGardenPduService.representative_email') }}</b-td>
                      <b-td>
                        <ValidationProvider name="Email" vid="email" rules="email|min:3">
                          <b-form-group
                            class="row"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            </template>
                            <b-form-input
                              id="email"
                              v-model="ins_gen_info.delegate_email"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td rowspan="2">{{ $n(6) }}</b-td>
                      <b-td>{{ $t('teaGardenPduService.paper_excuation_date') }}</b-td>
                      <b-td>
                        {{$t('globalTrans.date')}} {{ gardenInfo.land_lease_info?.lease_start_date | dateFormat}} {{$t('teaGardenPduService.to')}} {{gardenInfo.land_lease_info?.lease_end_date | dateFormat}} {{$t('globalTrans.total')}} {{$n(gardenInfo.land_lease_info?.lease_duration)}} {{$t('globalTrans.year')}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('teaGardenPduService.register_lease_no') }}</b-td>
                      <b-td>
                        {{$t('teaGardenConfig.reg_lease_deed_no')}} : {{$n(gardenInfo.land_lease_info?.reg_lease_deed_no, { useGrouping: false })}} {{$t('globalTrans.date')}} : {{ gardenInfo.land_lease_info?.lease_deed_date | dateFormat}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $n(7) }}</b-td>
                      <b-td>{{ $t('teaGardenPduService.current_class') }}</b-td>
                      <b-td>
                        <b-form-input v-model="ins_gen_info.tea_production_class_en" :placeholder="$t('teaGardenPduService.class_name')  +' '+ $t('globalTrans.en')"></b-form-input>
                        <br>
                        <b-form-input v-model="ins_gen_info.tea_production_class_bn" :placeholder="$t('teaGardenPduService.class_name')  +' '+ $t('globalTrans.bn')"></b-form-input>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $n(8) }}</b-td>
                      <b-td>{{ $t('teaGardenPduService.garden_short_history') }}</b-td>
                      <b-td>
                        {{ currentLocal == 'bn' ? gardenInfo.garden_history_bn : gardenInfo.garden_history_en }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
            </div>
          </b-overlay>
        </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { evaluationReportTab1Submit } from '../../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
import AddressHelper from '@/utils/area-type-address'
// import { VueEditor } from 'vue2-editor'
  export default {
    components: {
      // VueEditor
    },
    props: ['loadingTabOne', 'app_id', 'appDetailId', 'ins_gen_info', 'gardenInfo', 'applicationDetail', 'userList'],
    mixins: [ModalBaseMasterList],
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        AddressHelper: AddressHelper,
        tabIndex: 1
      }
    },
    computed: {
      currentLocal () {
        return this.$i18n.locale
      }
    },
    created () {
    },
    methods: {
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFiscalYear (yearId) {
      const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
      if (cateObj !== undefined) {
        if (this.$i18n.locale === 'bn') {
          return cateObj.text_bn
        } else {
          return cateObj.text_en
        }
      }
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async submit () {
        var check = await this.$refs.form.validate()
        if (check) {
            this.loadingTabOne = true

            this.ins_gen_info.app_id = this.app_id
            this.ins_gen_info.inspecting_officers = this.userList
            this.ins_gen_info.garden_id = this.gardenInfo.id
            this.ins_gen_info.ins_garden_info_id = this.appDetailId
            this.ins_gen_info.fiscal_year_id = this.applicationDetail.application?.fiscal_year_id

            const result = await RestApi.postData(teaGardenServiceBaseUrl, evaluationReportTab1Submit, this.ins_gen_info)
            this.loadingTabOne = false
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                return result
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: result.message,
                    color: '#ee5253'
                })
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
  }
  }
</script>
<style>
.ev-tab-elements .form-control{
    margin: 0;
}
</style>
