<template>
<ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
        <b-overlay :show="loadingTab6">
        <!-- <pre>{{managementInfo}}</pre> -->
            <div class="vertical-middle ev-tab-elements">
                <section>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '১.' : '1.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'ব্যবস্থাপকের নাম (মোবাইল ও ই-মেইল সহ)' : 'Name of Manager (including mobile and e-mail)' }}</b-th>
                                <b-td>
                                    <b-form-input
                                        :placeholder="$t('globalTrans.name') + ' ' + $t('globalTrans.en')"
                                        v-model="managementInfo.manager_name_en">
                                    </b-form-input>
                                    <br>
                                    <b-form-input
                                        :placeholder="$t('globalTrans.name') + ' ' + $t('globalTrans.bn')"
                                        v-model="managementInfo.manager_name_bn">
                                    </b-form-input>
                                    <br>
                                    <ValidationProvider name="Manager Email" vid="manager_email" rules="email|min:3">
                                        <b-form-group
                                            class="row"
                                            label-for="manager_email"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            </template>
                                            <b-form-input
                                            id="manager_email"
                                             :placeholder="$t('globalTrans.email')"
                                            v-model="managementInfo.manager_email"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <br>
                                    <ValidationProvider name="Manager Mobile" vid="manager_mobile" rules="digits:11">
                                        <b-form-group
                                            class="row"
                                            label-for="manager_mobile"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            </template>
                                            <b-form-input
                                            id="manager_mobile"
                                             :placeholder="$t('globalTrans.mobile')"
                                            v-model="managementInfo.manager_mobile"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '২.' : '2.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'উপ- ব্যবস্থাপকের নামসহ সংখ্যা (মোবাইল ও ই-মেইল সহ)' : 'Deputy Manager Name with Number (including Mobile and E-mail)' }}</b-th>
                                 <b-td>
                                    <b-form-input
                                        :placeholder="$t('globalTrans.name') + ' ' + $t('globalTrans.en')"
                                        v-model="managementInfo.deputy_manager_name_en">
                                    </b-form-input>
                                    <br>
                                    <b-form-input
                                        :placeholder="$t('globalTrans.name') + ' ' + $t('globalTrans.bn')"
                                        v-model="managementInfo.deputy_manager_name_bn">
                                    </b-form-input>
                                    <br>
                                    <ValidationProvider name="Deputy Manager Email" vid="deputy_manager_email" rules="email|min:3">
                                        <b-form-group
                                            class="row"
                                            label-for="deputy_manager_email"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            </template>
                                            <b-form-input
                                            id="deputy_manager_email"
                                             :placeholder="$t('globalTrans.email')"
                                            v-model="managementInfo.deputy_manager_email"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <br>
                                    <ValidationProvider name="Deputy Manager Mobile" vid="deputy_manager_mobile" rules="digits:11">
                                        <b-form-group
                                            class="row"
                                            label-for="deputy_manager_mobile"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            </template>
                                            <b-form-input
                                            id="deputy_manager_mobile"
                                             :placeholder="$t('globalTrans.mobile')"
                                            v-model="managementInfo.deputy_manager_mobile"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '৩.' : '3.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'সহকারী ব্যবস্থাপকের নাম সহ সংখ্যা (মোবাইল ও ইমেইল সহ)' : 'Assistant Manager Name with Number (including Mobile and Email)' }}</b-th>
                               <b-td>
                                    <b-form-input
                                        :placeholder="$t('globalTrans.name') + ' ' + $t('globalTrans.en')"
                                        v-model="managementInfo.assistant_manager_name_en">
                                    </b-form-input>
                                    <br>
                                    <b-form-input
                                        :placeholder="$t('globalTrans.name') + ' ' + $t('globalTrans.bn')"
                                        v-model="managementInfo.assistant_manager_name_bn">
                                    </b-form-input>
                                    <br>
                                    <ValidationProvider name="Assistant Manager Email" vid="assistant_manager_email" rules="email|min:3">
                                        <b-form-group
                                            class="row"
                                            label-for="assistant_manager_email"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            </template>
                                            <b-form-input
                                            id="assistant_manager_email"
                                             :placeholder="$t('globalTrans.email')"
                                            v-model="managementInfo.assistant_manager_email"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <br>
                                    <ValidationProvider name="Assistant Manager Mobile" vid="assistant_manager_mobile" rules="digits:11">
                                        <b-form-group
                                            class="row"
                                            label-for="assistant_manager_mobile"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            </template>
                                            <b-form-input
                                            id="assistant_manager_mobile"
                                             :placeholder="$t('globalTrans.mobile')"
                                            v-model="managementInfo.assistant_manager_mobile"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '৪.' : '4.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'কর্মচারী সংখ্যা (মাঠ)' : 'Number of employees (field)' }}</b-th>
                                <b-td>
                                    <b-form-input
                                     v-model="managementInfo.field_employee_number"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '৫.' : '5.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'কর্মচারী সংখ্যা (কারখানা)' : 'Number of Employees (Factory)' }}</b-th>
                                <b-td>
                                    <b-form-input
                                     v-model="managementInfo.factory_employee_number"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '৬.' : '6.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'অন্যন্য মাসিক বেতনভুক্ত কর্মচারী (সংখ্যা)' : 'Other Monthly Salaried Employees (No.)' }}</b-th>
                                <b-td>
                                    <b-form-input
                                     v-model="managementInfo.other_employee_number"
                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="3">
                                    <small>{{ currentLocale === 'bn' ? 'পর্যবেক্ষণ এবং মন্তব্যঃ' : 'Observations and Comments:' }} </small>
                                     <b-form-textarea :placeholder="$t('globalTrans.en')" v-model="managementInfo.comment_en"></b-form-textarea>
                                    <br>
                                    <b-form-textarea :placeholder="$t('globalTrans.bn')" v-model="managementInfo.comment_bn"></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section>
                    <b-table-simple responsive bordered>
                        <b-tbody>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '৭.' : '7.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'বিগত পরিদর্শন/পরিবীক্ষণ প্রতিবেদনের সুপারিশের এলাকে গৃহীত ব্যবস্থা ও মতামতঃ' : 'Actions and opinions taken on recommendations of previous inspections/inspection reports:' }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="2">
                                     <b-form-textarea :placeholder="$t('globalTrans.en')" v-model="managementInfo.actions_en"></b-form-textarea>
                                    <br>
                                    <b-form-textarea :placeholder="$t('globalTrans.bn')" v-model="managementInfo.actions_bn"></b-form-textarea>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ currentLocale === 'bn' ? '৮.' : '8.' }}</b-th>
                                <b-th>{{ currentLocale === 'bn' ? 'বাগান উন্নয়নে প্রতিবন্ধকতা এবং  সমাধানের বিষয়ে পরিদর্শনকারী কর্মকর্তার বিস্তারিত মতামতঃ' : 'Detailed opinion of the visiting officer on the obstacles and solutions in garden development:' }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="2">
                                     <b-form-textarea :placeholder="$t('globalTrans.en')" v-model="managementInfo.detailed_opinion_en"></b-form-textarea>
                                    <br>
                                    <b-form-textarea :placeholder="$t('globalTrans.bn')" v-model="managementInfo.detailed_opinion_bn"></b-form-textarea>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
                <section class="mt-5 pt-5">
                    <br>
                    <p class="border-top pt-2 mt-4 d-inline">{{ currentLocale === 'bn' ? 'পরিদর্শনকারী কর্মকর্তা/কর্মচারীদের স্বাক্ষর' : 'Signature of Inspecting Officer/Employee' }}</p>
                </section>
            </div>
        </b-overlay>
    </b-form>
</ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { managementInfoStoreUpdate } from '../../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
export default {
    props: ['loadingTab6', 'ins_gen_info_id', 'fiscalYearId', 'appDetailId', 'management_info'],
    mixins: [ModalBaseMasterList],
    data () {
        return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
            managementInfo: ''
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    created () {
        if (this.management_info) {
            this.managementInfo = this.management_info
        }
    },
    methods: {
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.loadingTab6 = true
                this.management_info.inspection_id = this.ins_gen_info_id

                const result = await RestApi.postData(teaGardenServiceBaseUrl, managementInfoStoreUpdate, this.management_info)
                if (result.success) {
                    this.loadingTab6 = false
                    this.management_info.save_update = 1
                    this.$toast.success({
                        title: 'Success',
                        message: this.management_info.save_update ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
<style>
.vertical-middle table tr th,
.vertical-middle table tr td {
    vertical-align: middle;
}</style>
