<template>
  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
    <b-overlay :show="loadingSubTab4">
      <div class="ev-tab-elements">
          <section class="">
              <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১০. শ্রম কল্যাণ সুযোগ সুবিধা (বিগত ০৩ বছর):' : '10. Labor Welfare Benefits (Last 03 Years):' }}</h6>
              <b-table-simple responsive bordered>
                  <b-thead class="text-center">
                      <b-tr>
                        <!-- {{ labWelBenifits }} -->
                          <b-th colspan="2">{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                          <template v-for="(fiscalItem, index) in labWelBenifits">
                            <b-th :key="index">{{ getFiscalYearName(fiscalItem.fiscal_year_id) }}</b-th>
                          </template>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                      <b-tr>
                          <b-th colspan="2">{{ currentLocale === 'bn' ? '(ক) শ্রমিক বাসস্থান' : '(a) Labor accommodation' }}</b-th>
                          <b-td></b-td>
                          <b-td></b-td>
                          <b-td></b-td>
                      </b-tr>
                      <b-tr>
                          <b-td></b-td>
                          <b-td>{{ currentLocale === 'bn' ? '(১) পাকা/মিরতিংগা টাইপ' : '(1) Paka/Mirtinga type' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'seasoned_type-' + index">
                            <b-form-input
                              plain
                              v-model="obj.seasoned_type"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td></b-td>
                          <b-td>{{ currentLocale === 'bn' ? '(২) মাটি টিনের ছাউনি' : '(2) Soil Tin Canopy' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'soil_tin_canopy-' + index">
                            <b-form-input
                              plain
                              v-model="obj.soil_tin_canopy"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td></b-td>
                          <b-td>{{ currentLocale === 'bn' ? '(৩) কাঁচা / বেড়া ও টিনের / ছনের ছাউনি' : '(3) Thatched / Fencing and Tin / Canopy' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'raw_type-' + index">
                            <b-form-input
                              plain
                              v-model="obj.raw_type"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr class="text-center">
                          <b-td colspan="2"><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                          <!-- <b-td> -->
                            <template v-for="(obj, index) in labWelBenifits">
                              <!-- {{ obj }} -->
                              <b-td :key="index">
                                <strong>
                                  {{ $n((obj.seasoned_type > 0 ? parseInt(obj.seasoned_type) : 0) + (obj.soil_tin_canopy > 0 ? parseInt(obj.soil_tin_canopy) : 0 ) + (obj.raw_type > 0 ? parseInt(obj.raw_type) : 0)) }}
                                </strong>
                              </b-td>
                            </template>
                      </b-tr>
                  </b-tbody>
              </b-table-simple>
          </section>
          <section class="">
              <b-table-simple responsive bordered>
                  <b-thead class="text-center">
                    <b-tr>
                      <b-th colspan="2">{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                      <template v-for="(fiscalItem, index) in labWelBenifits">
                        <b-th :key="index">{{ getFiscalYearName(fiscalItem.fiscal_year_id) }}</b-th>
                      </template>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                      <b-tr>
                          <b-th colspan="2">{{ currentLocale === 'bn' ? '(ক) পয়ঃনিষ্কাশন ব্যবস্থা' : '(a) Sewage system' }}</b-th>
                          <b-td></b-td>
                          <b-td></b-td>
                          <b-td></b-td>
                      </b-tr>
                      <b-tr>
                          <b-td rowspan="2"></b-td>
                          <b-td>{{ currentLocale === 'bn' ? '(১) কাঁচা লেট্রিন' : '(1) Raw latrine' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'raw_latrines-' + index">
                            <!-- {{ obj }} -->
                            <b-form-input
                              plain
                              v-model="obj.raw_latrines"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) পাকা লেট্রিন' : '(2) Paved latrines' }}</b-td>
                        <b-td v-for="(obj, index) in labWelBenifits" :key="'paved_latrines-' + index">
                          <b-form-input
                            plai
                            v-model="obj.paved_latrines"
                          >
                          </b-form-input>
                        </b-td>
                      </b-tr>
    <!-- **************** (গ) স্বাস্থ্য সেবা ***************************************** -->
                      <b-tr>
                          <b-th colspan="2">{{ currentLocale === 'bn' ? '(গ) স্বাস্থ্য সেবাঃ' : '(c) Health Services:' }}</b-th>
                          <b-td></b-td>
                          <b-td></b-td>
                          <b-td></b-td>
                      </b-tr>
                      <b-tr>
                          <b-td rowspan="11"></b-td>
                          <b-td>{{ currentLocale === 'bn' ? '(১) হাসপাতাল' : '(1) Hospital' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'hospital-' + index">
                            <b-form-input
                              plain
                              v-model="obj.hospital"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(২) ডিসপেনসারি' : '(2) Dispensaries' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'dispensaries-' + index">
                            <b-form-input
                              plain
                              v-model="obj.dispensaries"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৩) বিছানা সংখ্যা' : '(3) Number of beds' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'bed_number-' + index">
                            <b-form-input
                              plain
                              v-model="obj.bed_number"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৪) চিকিৎসক (এমবিবিএস)' : '(4) Physician (MBBS)' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'physician_mbbs-' + index">
                            <b-form-input
                              plain
                              v-model="obj.physician_mbbs"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৫) চিকিৎসক (এলএমএফ)' : '(5) Physician (LMF)' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'physician_lmf-' + index">
                            <b-form-input
                              plain
                              v-model="obj.physician_lmf"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৬) কম্পাউন্ডার' : '(6) Compounders' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'compounder-' + index">
                            <b-form-input
                              plain
                              v-model="obj.compounder"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৭) মিডওয়াইফ/নার্স' : '(7) Midwife/Nurse' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'midwife-' + index">
                            <b-form-input
                              plain
                              v-model="obj.midwife"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৮) ড্রেসার' : '(8) Dresser' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'dresser-' + index">
                            <b-form-input
                              plain
                              v-model="obj.dresser"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৯) দাই' : '(9) Dai' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'responsible-' + index">
                            <b-form-input
                              plain
                              v-model="obj.responsible"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(১০) ক্রেস্ট হাউজ' : '(10) Crest House' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'crest_house-' + index">
                            <b-form-input
                              plain
                              v-model="obj.crest_house"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(১১) ক্লাস এটেনডেন্ট' : '(11) Crest Attendant' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'crest_attendant-' + index">
                            <b-form-input
                              plain
                              v-model="obj.crest_attendant"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
    <!-- **************** (ঘ) বিশুদ্ধ পানি সরবরাহ ***************************************** -->
                      <b-tr>
                          <b-th colspan="2">{{ currentLocale === 'bn' ? '(ঘ) বিশুদ্ধ পানি সরবরাহ' : '(d) Supply of clean water' }}</b-th>
                          <b-td></b-td>
                          <b-td></b-td>
                          <b-td></b-td>
                      </b-tr>
                      <b-tr>
                          <b-td rowspan="4"></b-td>
                          <b-td>{{ currentLocale === 'bn' ? '(১) গভীর নলকূপ' : '(1) Deep Tube Wells' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'deep_tube_wells-' + index">
                            <b-form-input
                              plain
                              v-model="obj.deep_tube_wells"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(২) হস্ত চালিত নলকূপ' : '(2) Hand driven tube wells' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'hand_driven_tube_wells-' + index">
                            <b-form-input
                              plain
                              v-model="obj.hand_driven_tube_wells"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৩) কুয়া (পাকা)' : '(3) kuya (ripe)' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'well_mature-' + index">
                            <b-form-input
                              plain
                              v-model="obj.well_mature"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৪) কুয়া (কাঁচা)' : '(4) Kuya (raw)' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'well_raw-' + index">
                            <b-form-input
                              plain
                              v-model="obj.well_raw"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
    <!-- **************** (ঙ) শিক্ষা গ্রহণের সুযোগ (বাগানের ভিতরে) ***************************************** -->
                      <b-tr>
                          <b-th colspan="2">{{ currentLocale === 'bn' ? '(ঙ) শিক্ষা গ্রহণের সুযোগ (বাগানের ভিতরে)' : '(e) Educational opportunities (within garden)' }}</b-th>
                          <b-td></b-td>
                          <b-td></b-td>
                          <b-td></b-td>
                      </b-tr>
                      <b-tr>
                          <b-td rowspan="9"></b-td>
                          <b-td>{{ currentLocale === 'bn' ? '(১) প্রাথমিক বিদ্যালয় সংখ্যা (পাকা)' : '(1) Number of Primary Schools (Mature)' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'primary_schools_mature-' + index">
                            <b-form-input
                              plain
                              v-model="obj.primary_schools_mature"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(২) প্রাথমিক বিদ্যালয় সংখ্যা (কাঁচা)' : '(2) Number of Primary Schools (Raw)' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'primary_schools_raw-' + index">
                            <b-form-input
                              plain
                              v-model="obj.primary_schools_raw"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৩) ছাত্র/ছাত্রী সংখ্যা' : '(3) Number of students' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'students-' + index">
                            <b-form-input
                              plain
                              v-model="obj.students"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৪) শিক্ষক/শিক্ষিকা সংখ্যা' : '(4) Number of teachers' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'teachers-' + index">
                            <b-form-input
                              plain
                              v-model="obj.teachers"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৫) স্কুলে আঙ্গিনায় অবস্থিত লেট্রিন সংখ্যা' : '(5) Number of latrines located in school premises' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'school_latrines-' + index">
                            <b-form-input
                              plain
                              v-model="obj.school_latrines"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৬) সরকারী প্রাথমিক বিদ্যালয় সংখ্যা' : '(6) Number of Government Primary Schools' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'govt_primary_schools-' + index">
                            <b-form-input
                              plain
                              v-model="obj.govt_primary_schools"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৭) নিবন্ধিত/ এমপ্রি ভুক্ত বিদ্যালয় সংখ্যা' : '(7) Number of schools registered/MPRs' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'registered_schools-' + index">
                            <b-form-input
                              plain
                              v-model="obj.registered_schools"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৮) এনজিও পরিচালিত বিদ্যালয় সংখ্যা' : '(8) Number of schools run by NGOs' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'ngo_schools-' + index">
                            <b-form-input
                              plain
                              v-model="obj.ngo_schools"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(৯) বাগান ব্যবস্থাপনায় পরিচালিত বিদ্যালয় সংখ্যা' : '(9) Number of schools under garden management' }}</b-td>
                          <b-td v-for="(obj, index) in labWelBenifits" :key="'forkaniya_madrasa-' + index">
                            <b-form-input
                              plain
                              v-model="obj.forkaniya_madrasa"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td colspan="10">
                              <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                              <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                          </b-td>
                      </b-tr>
                  </b-tbody>
              </b-table-simple>
          </section>
      </div>
    </b-overlay>
  </b-form>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { statsPartFourStoreUpdate } from '../../../../../api/routes'

export default {
  props: ['loadingSubTab4', 'appDetailId', 'app_id', 'main_tab_data', 'stats_id'],
  data () {
      return {
        labWelBenifits: [],
        stats4Data: {},
        loading: false
      }
  },
  computed: {
      currentLocale () {
          return this.$i18n.locale
      },
      fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      }
  },
  created () {
    if (this.main_tab_data && this.main_tab_data.lab_wel_benefit?.length > 0) {
      this.labWelBenifits = this.main_tab_data.lab_wel_benefit
    } else {
      this.previousTwoYearFiscalYearId()
    }
  },
  methods: {
    previousTwoYearFiscalYearId () {
      const obj = this.fiscalYearList.find(item => item.value === parseInt(this.$store.state.currentFiscalYearId))
      const fiscalYearListSort = this.fiscalYearList.filter(item => item.sorting_order <= obj.sorting_order).sort((a, b) => b.sorting_order - a.sorting_order)
      const labWelBenifits = []
      fiscalYearListSort.forEach(item => {
        if (labWelBenifits.length < 3) {
          labWelBenifits.push({
            fiscal_year_id: item.value,
            seasoned_type: '',
            soil_tin_canopy: '',
            raw_type: '',
            total_worker_residence: '',
            raw_latrines: '',
            paved_latrines: '',
            hospital: '',
            dispensaries: '',
            bed_number: '',
            physician_mbbs: '',
            physician_lmf: '',
            compounder: '',
            midwife: '',
            dresser: '',
            responsible: '',
            crest_house: '',
            crest_attendant: '',
            deep_tube_wells: '',
            hand_driven_tube_wells: '',
            well_mature: '',
            well_raw: '',
            primary_schools_mature: '',
            primary_schools_raw: '',
            students: '',
            teachers: '',
            school_latrines: '',
            govt_primary_schools: '',
            registered_schools: '',
            ngo_schools: '',
            forkaniya_madrasa: ''
          })
        }
      })
      // labWelBenifits.push(this.$store.state.currentFiscalYearId)
      this.labWelBenifits = labWelBenifits
    },
    async saveUpdate () {
      this.loadingSubTab4 = true

      const params = Object.assign({}, this.labWelBenifits, { stat_info_id: this.stats_id })
      try {
        const response = await RestApi.postData(teaGardenServiceBaseUrl, statsPartFourStoreUpdate, params)
        this.loadingSubTab4 = false
        if (response.success) {
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.stats_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          return response
        } else {
          this.$refs.form.setErrors(response.data.errors)
        }
      } catch (error) {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: error,
          color: '#FF6347'
        })
      }
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
<style></style>
