<template>
  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
    <b-overlay :show="loadingSubTab1">
      <div class="ev-tab-elements">
        <section class="porisonkhan-table-one">
          <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? 'সরকার কতৃক ইজারাধীন ভূমিতে সৃজিত চা বাগানের ক্ষেতেঃ' : 'In tea plantations created on government leased land:' }}</h6>
          <b-table-simple responsive bordered>
              <b-thead>
                  <b-tr>
                      <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'Order' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'একর' : 'acre' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'Order' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'জাত ও সংগ্রহের স্থান' : 'Caste and Place of Collection' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'একর' : 'acre' }}</b-th>
                  </b-tr>
              </b-thead>
              <b-tbody>
                  <b-tr>
                      <b-td>{{ $n(1) }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'মোট জমি পরিমাণ' : 'Total land area' }} <small class="text-muted">{{ currentLocale === 'bn' ? '(ইজারাধীন)' : '(under lease)' }}</small></b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_total_land_area"
                          v-model="stats1Data.gov_leased_total_land_area"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>-</b-td>
                      <b-td>-</b-td>
                      <b-td>
                      </b-td>
                      <b-td>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '২.(ক)' : '2. (a)' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চা চাষের অধীনে জমির পরিমাণ' : 'Amount of land under tea cultivation' }}</b-td>
                      <b-td></b-td>
                      <b-td>{{ currentLocale === 'bn' ? '২.(খ)' : '2. (b)' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চা উৎপন্ন হয় না এরূপ চা এলাকা' : 'Such tea areas do not produce tea' }}</b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                  </b-tr>
                  <b-tr>
                      <b-td rowspan="5"></b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'ক. অপরিণত চা গাছ (১-৫ বছর)' : 'A. Immature tea plants (1-5 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_immature_tea_plant"
                          @keyup="cal2AData(1)"
                          v-model="stats1Data.gov_leased_immature_tea_plant"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td rowspan="5"></b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চ. ক্লোন নার্সারী' : 'f. Clone Nursery' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_clone_nursery_place_en"
                          v-model="stats1Data.gov_leased_clone_nursery_place_en"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                          plain
                          id="gov_leased_clone_nursery_place_bn"
                          v-model="stats1Data.gov_leased_clone_nursery_place_bn"
                          :placeholder="$t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_clone_nursery"
                          v-model="stats1Data.gov_leased_clone_nursery"
                          @keyup="cal2BData(1)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? 'খ. নতুন চা গাছ (৬-১০ বৎছর)' : 'b. New tea plants (6-10 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_new_tea_plant"
                          @keyup="cal2AData(1)"
                          v-model="stats1Data.gov_leased_new_tea_plant"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'ছ. বীজ নার্সারী' : 'G. Seed nursery' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_seed_nursery_place_en"
                          v-model="stats1Data.gov_leased_seed_nursery_place_en"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                          plain
                          id="gov_leased_seed_nursery_place_bn"
                          v-model="stats1Data.gov_leased_seed_nursery_place_bn"
                          :placeholder="$t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_seed_nursery"
                          v-model="stats1Data.gov_leased_seed_nursery"
                          @keyup="cal2BData(1)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? 'গ. পূর্ণ বয়স্ক চা গাছ (১১-৪০ বৎসর)' : 'c. Mature tea tree (11-40 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_mature_tea_plant"
                          @keyup="cal2AData(1)"
                          v-model="stats1Data.gov_leased_mature_tea_plant"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'জ. ছায়াতরু নার্সারী' : 'h. Chhayataru Nursery' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_shade_nursery_place_en"
                          v-model="stats1Data.gov_leased_shade_nursery_place_en"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                          plain
                          id="gov_leased_shade_nursery_place_bn"
                          v-model="stats1Data.gov_leased_shade_nursery_place_bn"
                          :placeholder="$t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_shade_nursery"
                          v-model="stats1Data.gov_leased_shade_nursery"
                          @keyup="cal2BData(1)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? 'ঘ. বয়স্ক চা গাছ (৪১ - ৬০ বছর)' : 'd. Older tea plants (41 - 60 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_older_tea_plant"
                          @keyup="cal2AData(1)"
                          v-model="stats1Data.gov_leased_older_tea_plant"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'ঝ. বীজ বাড়ী' : 'i. Seed house' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_seed_house_place_en"
                          v-model="stats1Data.gov_leased_seed_house_place_en"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                          plain
                          id="gov_leased_seed_house_place_bn"
                          v-model="stats1Data.gov_leased_seed_house_place_bn"
                          :placeholder="$t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_seed_house"
                          v-model="stats1Data.gov_leased_seed_house"
                          @keyup="cal2BData(1)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ currentLocale === 'bn' ? 'ঙ. অতি বয়স্ক চা গাছ (৬০ বছর উর্ধ্ব)' : 'e. Very old tea tree (above 60 years)' }}</b-td>
                    <b-td>
                      <b-form-input
                        plain
                        id="gov_leased_very_old_tea_plant"
                        @keyup="cal2AData(1)"
                        v-model="stats1Data.gov_leased_very_old_tea_plant"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      >
                      </b-form-input>
                    </b-td>
                    <b-td>{{ currentLocale === 'bn' ? 'ঞ. এনসিপি' : 'j. NCP' }}</b-td>
                    <b-td>
                      <b-form-input
                        plain
                        id="gov_leased_ncp_place_en"
                        v-model="stats1Data.gov_leased_ncp_place_en"
                        :placeholder="$t('globalTrans.en')"
                      >
                      </b-form-input>
                      <br>
                      <b-form-input
                        plain
                        id="gov_leased_ncp_place_nn"
                        v-model="stats1Data.gov_leased_ncp_place_bn"
                        :placeholder="$t('globalTrans.bn')"
                      >
                      </b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        plain
                        id="gov_leased_ncp"
                        v-model="stats1Data.gov_leased_ncp"
                        @keyup="cal2BData(1)"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                      </b-form-input>
                    </b-td>
                  </b-tr>
              </b-tbody>
              <b-tfoot>
                  <b-tr>
                      <b-th colspan="2" class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-২ (ক)' : 'Sub-II (A)' }}</b-th>
                      <b-th class="text-right">{{ $n(stats1Data.gov_leased_cultivation_land) }}</b-th>
                      <b-th colspan="3" class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-২ (খ)' : 'Sub-II (B)' }}</b-th>
                      <b-th class="text-right">{{ $n(stats1Data.gov_leased_areas_dont_produce_tea) }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="7">
                      <h6 class="font-weight-bold mb-2 text-left">{{ currentLocale === 'bn' ? ' চা আবাদীতে ব্যবহৃত মোট ভূমি (উপমোট ২/ক + ২/খ):' : 'Total land used in cultivation (subtotal 2/A + 2/B):' }} {{ $n(stats1Data.gov_leased_total_cultivation_land)}}</h6>
                      </b-td>
                  </b-tr>
              </b-tfoot>
          </b-table-simple>
        </section>
        <section class="porisonkhan-table-two">
            <!-- <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? ' চা আবাদীতে ব্যবহৃত মোট ভূমি (উপমোট ২/ক + ২/খ):' : 'Total land used in cultivation (subtotal 2/A + 2/B):' }}</h6> -->
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '৩.(ক)' : '3. (A)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা বহির্ভূত এলাকা (একর)' : 'Non-Tea Area (Acres)' }}</b-td>
                        <b-td>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td rowspan="3"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) (চা আবাদী হ্রাস) চা উৎপাটন' : '(1) (Reduction in tea plantations) Production of tea' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="gov_leased_tea_production"
                            v-model="stats1Data.gov_leased_tea_production"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3AData(1)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) (চা আবাদী হ্রাস) মাটি পুনর্বাসন' : '(2) (Reduction of tea plantations) Soil rehabilitation' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="gov_leased_soil_rehabilitation"
                            v-model="stats1Data.gov_leased_soil_rehabilitation"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3AData(1)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '(৩) চা আবাদি পরিত্যক্ত (যদি থাকে)' : '(3) Tea plantations abandoned (if any).' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="gov_leased_tea_plantation_abandoned"
                            v-model="stats1Data.gov_leased_tea_plantation_abandoned"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3AData(1)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right" colspan="2">{{ currentLocale === 'bn' ? 'উপমোট-৩ (ক)' : 'Subtotal-3 (A)' }}</b-th>
                      <b-th>{{$n(stats1Data.gov_leased_non_tea_area)}}</b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="porisonkhan-table-three">
          <b-table-simple responsive bordered>
              <b-thead>
                  <b-tr>
                      <b-th>{{ currentLocale === 'bn' ? '৩.(খ)' : '3.(B)' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'অন্যন্য কৃষি কাজে ব্যবহৃত ভূমির পরিমাণ' : 'Amount of land used for other agricultural purposes' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'একর' : 'Acre' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? '৩.(গ)' : '3.(C)' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'কৃষি বহির্ভূত কাজে ব্যবহৃত ভূমির পরিমাণ' : 'Amount of land used for non-agricultural purposes' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'একর' : 'Acre' }}</b-th>
                  </b-tr>
              </b-thead>
              <b-tbody>
                  <b-tr>
                      <b-td rowspan="8"></b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(১) রাবার' : '(1) Rubber' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_rubber"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_rubber"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td rowspan="8"></b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(১) পতিত জমি' : '(1) Fallen land' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_fallen_land"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3CData(1)"
                          v-model="stats1Data.gov_leased_fallen_land"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(২) বাঁশ' : '(2) Bamboo' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_bamboo"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_bamboo"

                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(২) জলাশয়/পুকুর' : '(2) Water bodies/ponds' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_water_bodies"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3CData(1)"
                          v-model="stats1Data.gov_leased_water_bodies"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(৩) রোপিত গাছ' : '(3) Planted trees' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_plated_trees"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_plated_trees"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(৩) মসজিদ/মন্দির/গীর্জা' : '(3) Mosque/Temple/Church' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_mosque"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3CData(1)"
                          v-model="stats1Data.gov_leased_mosque"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(৪) প্রাকৃতিক জঙ্গল' : '(4) Natural forest' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_natural_forest"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_natural_forest"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(৪) করব/শ্মশান' : '(4) cremation/cremation' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_grave"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3CData(1)"
                          v-model="stats1Data.gov_leased_grave"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(৫) ছন' : '(5) Chhan' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_mats"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_mats"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(৫) শ্রমিক বসতি/কর্মচারী গৃহ' : '(5) Labor settlement/employee housing' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_labor_settlement"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3CData(1)"
                          v-model="stats1Data.gov_leased_labor_settlement"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(৬) ধান ক্ষেত' : '(6) Paddy field' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_paddy_field"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_paddy_field"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(৬) কারখানা/বাংলো/অফিস' : '(6) Factory/Bungalow/Office' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_factory"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3CData(1)"
                          v-model="stats1Data.gov_leased_factory"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(৭) কাঁঠাল গাছ/ লেবু' : '(7) Jackfruit tree/lemon' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_jack_fruit_lemon"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_jack_fruit_lemon"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(৭) স্কুল/হাসপাতাল/ ডিসপেনসারি' : '(7) Schools/Hospitals/Dispensaries' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_school"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                           @keyup="cal3CData(1)"
                          v-model="stats1Data.gov_leased_school"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(৮) অন্যান্য ফলজ ও অর্থকরী ফসল' : '(8) Other fruits and cash crops' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_other_fruits"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3BData(1)"
                          v-model="stats1Data.gov_leased_other_fruits"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(৮) রাস্তা, সেকশন রাস্তা সহ' : '(8) Roads, including section roads' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_road"
                          v-model="stats1Data.gov_leased_road"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3CData(1)"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-th></b-th>
                      <b-th class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-৩ (খ)' : 'Subtotal-3 (B)' }}</b-th>
                      <b-th>{{$n(stats1Data.gov_leased_other_agricultural_land)}}</b-th>
                      <b-th></b-th>
                      <b-th class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-৩ (গ)' : 'Subtotal-3 (C)' }}</b-th>
                      <b-th>{{$n(stats1Data.gov_leased_non_agricultural_area)}}</b-th>
                  </b-tr>
              </b-tbody>
          </b-table-simple>
          <b-table-simple bordered>
              <b-tbody>
                  <b-tr>
                      <b-td colspan="3">{{ currentLocale === 'bn' ? 'ইজারাকৃত মোট অনুমোদিত জমি উপ-মোট ২(ক) + ২(খ) + ৩(ক) + ৩(খ) + ৩(গ)' : 'Total sanctioned land leased Sub-total 2(a) + 2(b) + 3(a) + 3(b) + 3(c)' }}</b-td>
                      <b-td colspan="3">{{$n(stats1Data.gov_leased_total_sanctioned_leased_land)}}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '৪ (ক)' : '4 (A)' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চা আবাদের শতকরা হার % (ইজারাধীন জমির তুলনায়)' : 'Percentage of tea plantations % (compared to leased land)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_plan_per_com_leased_land"
                          v-model="stats1Data.gov_leased_plan_per_com_leased_land"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '৪ (ক)' : '4 (A)' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চা আবাদের শতকরা হার % (চা চাষ উপযোগী জমির তুলনায়)' : 'Percentage of tea plantations % (Compared to land suitable for tea cultivation)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="gov_leased_plan_per_com_suitable_land"
                          v-model="stats1Data.gov_leased_plan_per_com_suitable_land"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '৫' : '5' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'ভূমি প্রকৃতির পরিমাণ ও শতকরা হার (%)' : 'Amount and percentage (%) of soil nature' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'টিলা:' : 'The Hill' }}
                        <b-form-input
                          plain
                          id="gov_leased_plan_hill_percentage"
                          v-model="stats1Data.gov_leased_plan_hill_percentage"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'উচ্চ সমভূমি:' : 'High Plains' }}
                        <b-form-input
                          plain
                          id="gov_leased_plan_high_plains_percentage"
                          v-model="stats1Data.gov_leased_plan_high_plains_percentage"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'সমভূমি' : 'Level Ground' }}
                        <b-form-input
                          plain
                          id="gov_leased_plan_plain_percentage"
                          v-model="stats1Data.gov_leased_plan_plain_percentage"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'মোট:' : 'Total:' }}
                      </b-td>
                  </b-tr>
              </b-tbody>
          </b-table-simple>
        </section>
        <section class="porisonkhan-table-one">
          <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? 'ব্যক্তি মালিকানাধীন অথবা নিজস্ব ভূমিতে সৃজিত চা বাগানের ক্ষেত্রেঃ' : 'In case of tea plantations owned by individuals or created on their own land:' }}</h6>
          <b-table-simple responsive bordered>
              <b-thead>
                  <b-tr>
                      <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'Order' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'একর' : 'acre' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'Order' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'জাত ও সংগ্রহের স্থান' : 'Caste and Place of Collection' }}</b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'একর' : 'acre' }}</b-th>
                  </b-tr>
              </b-thead>
              <b-tbody>
                  <b-tr>
                      <b-td>{{ $n(1) }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'মোট জমি পরিমাণ' : 'Total land area' }} <small class="text-muted">{{ currentLocale === 'bn' ? '(ইজারাধীন)' : '(under lease)' }}</small></b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_total_land_area"
                              v-model="stats1Data.individual_total_land_area"
                            >
                            </b-form-input>
                      </b-td>
                      <b-td>-</b-td>
                      <b-td>-</b-td>
                      <b-td>
                      </b-td>
                      <b-td>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '২.(ক)' : '2. (a)' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চা চাষের অধীনে জমির পরিমাণ' : 'Amount of land under tea cultivation' }}</b-td>
                      <b-td>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? '২.(খ)' : '2. (b)' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চা উৎপন্ন হয় না এরূপ চা এলাকা' : 'Such tea areas do not produce tea' }}</b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                  </b-tr>
                  <b-tr>
                      <b-td rowspan="5"></b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'ক. অপরিণত চা গাছ (১-৫ বছর)' : 'A. Immature tea plants (1-5 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_immature_tea_plant"
                              v-model="stats1Data.individual_immature_tea_plant"
                              @keyup="cal2AData(2)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                            </b-form-input>
                      </b-td>
                      <b-td rowspan="5"></b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চ. ক্লোন নার্সারী' : 'f. Clone Nursery' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="individual_clone_nursery_place_en"
                          v-model="stats1Data.individual_clone_nursery_place_en"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                          plain
                          id="individual_clone_nursery_place_bn"
                          v-model="stats1Data.individual_clone_nursery_place_bn"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_clone_nursery"
                              v-model="stats1Data.individual_clone_nursery"
                              @keyup="cal2BData(2)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                            </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? 'খ. নতুন চা গাছ (৬-১০ বৎছর)' : 'b. New tea plants (6-10 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_new_tea_plant"
                              v-model="stats1Data.individual_new_tea_plant"
                              @keyup="cal2AData(2)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                            </b-form-input>
                      </b-td>
                      <b-td>{{  currentLocale === 'bn' ? 'ছ. বীজ নার্সারী' : 'G. Seed nursery' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="individual_seed_nursery_place_en"
                          v-model="stats1Data.individual_seed_nursery_place_en"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                          plain
                          id="individual_seed_nursery_place_bn"
                          v-model="stats1Data.individual_seed_nursery_place_bn"
                          :placeholder="$t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_seed_nursery"
                              v-model="stats1Data.individual_seed_nursery"
                              @keyup="cal2BData(2)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                            </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? 'গ. পূর্ণ বয়স্ক চা গাছ (১১-৪০ বৎসর)' : 'c. Mature tea tree (11-40 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_mature_tea_plant"
                              v-model="stats1Data.individual_mature_tea_plant"
                              @keyup="cal2AData(2)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"

                            >
                            </b-form-input>
                      </b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'জ. ছায়াতরু নার্সারী' : 'h. Chhayataru Nursery' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="individual_shade_nursery_place_en"
                          v-model="stats1Data.individual_shade_nursery_place_en"
                          :placeholder="$t('globalTrans.en')"
                        >
                        </b-form-input>
                        <br>
                        <b-form-input
                          plain
                          id="individual_shade_nursery_place_bn"
                          v-model="stats1Data.individual_shade_nursery_place_bn"
                          :placeholder="$t('globalTrans.bn')"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_shade_nursery"
                              v-model="stats1Data.individual_shade_nursery"
                               @keyup="cal2BData(2)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                            </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? 'ঘ. বয়স্ক চা গাছ (৪১ - ৬০ বছর)' : 'd. Older tea plants (41 - 60 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="individual_older_tea_plant"
                          v-model="stats1Data.individual_older_tea_plant"
                           @keyup="cal2AData(2)"
                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? 'ঙ. অতি বয়স্ক চা গাছ (৬০ বছর উর্ধ্ব)' : 'e. Very old tea tree (above 60 years)' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="individual_very_old_tea_plant"
                          v-model="stats1Data.individual_very_old_tea_plant"
                           @keyup="cal2AData(2)"
                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        >
                        </b-form-input>
                      </b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                  </b-tr>
              </b-tbody>
              <b-tfoot>
                  <b-tr>
                      <b-th colspan="2" class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-২ (ক)' : 'Sub-II (A)' }}</b-th>
                      <b-th class="text-right">{{ $n(stats1Data.individual_cultivation_land) }}</b-th>
                      <b-th colspan="2" class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-২ (খ)' : 'Sub-II (B)' }}</b-th>
                      <b-th class="text-right"></b-th>
                      <b-th class="text-right">{{ $n(stats1Data.individual_areas_dont_produce_tea) }}</b-th>
                  </b-tr>
              </b-tfoot>
          </b-table-simple>
        </section>
        <section class="porisonkhan-table-two">
          <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? ' চা আবাদীতে ব্যবহৃত মোট ভূমি (উপমোট ২/ক + ২/খ):' : 'Total land used in cultivation (subtotal 2/A + 2/B):' }} {{ $n(stats1Data.individual_total_cultivation_land)}}</h6>
          <b-table-simple responsive bordered>
              <b-tbody>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '৩.(ক)' : '3. (A)' }}</b-td>
                      <b-td>{{ currentLocale === 'bn' ? 'চা বহির্ভূত এলাকা (একর)' : 'Non-Tea Area (Acres)' }}</b-td>
                      <b-td></b-td>
                  </b-tr>
                  <b-tr>
                      <b-td rowspan="3"></b-td>
                      <b-td>{{ currentLocale === 'bn' ? '(১) (চা আবাদী হ্রাস) চা উৎপাদন' : '(1) (Reduction in tea plantations) Production of tea' }}</b-td>
                      <b-td>
                        <b-form-input
                          plain
                          id="individual_tea_production"
                          v-model="stats1Data.individual_tea_production"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="cal3AData(2)"
                        >
                        </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale === 'bn' ? '(২) (চা আবাদী হ্রাস) মাটি পুনর্বাসন' : '(2) (Reduction of tea plantations) Soil rehabilitation' }}</b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_soil_rehabilitation"
                              v-model="stats1Data.individual_soil_rehabilitation"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3AData(2)"
                            >
                            </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td>{{ currentLocale == 'bn' ? '(৩) চা আবাদি পরিত্যক্ত (যদি থাকে)' : '(3) Tea plantations abandoned (if any).' }}</b-td>
                      <b-td>
                        <b-form-input
                              plain
                              id="individual_tea_plantation_abandoned"
                              v-model="stats1Data.individual_tea_plantation_abandoned"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              @keyup="cal3AData(2)"
                            >
                            </b-form-input>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-th class="text-right" colspan="2">{{ currentLocale === 'bn' ? 'উপমোট-৩ (ক)' : 'Subtotal-3 (A)' }}</b-th>
                      <b-th>{{$n(stats1Data.individual_non_tea_area)}}</b-th>
                    </b-tr>
              </b-tbody>
          </b-table-simple>
        </section>
        <section class="porisonkhan-table-three">
            <b-table-simple responsive bordered>
                <b-thead>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৩.(খ)' : '3.(B)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'অন্যন্য কৃষি কাজে ব্যবহৃত ভূমির পরিমাণ' : 'Amount of land used for other agricultural purposes' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'একর' : 'Acre' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '৩.(গ)' : '3.(C)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কৃষি বহির্ভূত কাজে ব্যবহৃত ভূমির পরিমাণ' : 'Amount of land used for non-agricultural purposes' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'একর' : 'Acre' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td rowspan="8"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) রাবার' : '(1) Rubber' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_rubber"
                            v-model="stats1Data.individual_rubber"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3BData(2)"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td rowspan="8"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) পতিত জমি' : '(1) Fallen land' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_fallen_land"
                            v-model="stats1Data.individual_fallen_land"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3CData(2)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) বাঁশ' : '(2) Bamboo' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_bamboo"
                            v-model="stats1Data.individual_bamboo"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3BData(2)"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(২) জলাশয়/পুকুর' : '(2) Water bodies/ponds' }}</b-td>
                        <b-td>
                          <b-form-input
                                plain
                                id="individual_water_bodies"
                                v-model="stats1Data.individual_water_bodies"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keyup="cal3CData(2)"
                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) রোপিত গাছ' : '(3) Planted trees' }}</b-td>
                        <b-td>
                          <b-form-input
                                plain
                                id="individual_plated_trees"
                                v-model="stats1Data.individual_plated_trees"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keyup="cal3BData(2)"
                              >
                              </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) মসজিদ/মন্দির/গীর্জা' : '(3) Mosque/Temple/Church' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_mosque"
                            v-model="stats1Data.individual_mosque"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3CData(2)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) প্রাকৃতিক জঙ্গল' : '(4) Natural forest' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_natural_forest"
                            v-model="stats1Data.individual_natural_forest"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3BData(2)"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) করব/শ্মশান' : '(4) cremation/cremation' }}</b-td>
                        <b-td>
                          <b-form-input
                                plain
                                id="individual_grave"
                                v-model="stats1Data.individual_grave"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keyup="cal3CData(2)"

                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) ছন' : '(5) Chhan' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_mats"
                            v-model="stats1Data.individual_mats"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3BData(2)"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) শ্রমিক বসতি/কর্মচারী গৃহ' : '(5) Labor settlement/employee housing' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_labor_settlement"
                            v-model="stats1Data.individual_labor_settlement"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3CData(2)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) ধান ক্ষেত' : '(6) Paddy field' }}</b-td>
                        <b-td>
                          <b-form-input
                                plain
                                id="individual_paddy_field"
                                v-model="stats1Data.individual_paddy_field"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keyup="cal3BData(2)"

                              >
                              </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) কারখানা/বাংলো/অফিস' : '(6) Factory/Bungalow/Office' }}</b-td>
                        <b-td>
                          <b-form-input
                                plain
                                id="individual_factory"
                                v-model="stats1Data.individual_factory"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keyup="cal3CData(2)"

                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) কাঁঠাল গাছ/ লেবু' : '(7) Jackfruit tree/lemon' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_jack_fruit_lemon"
                            v-model="stats1Data.individual_jack_fruit_lemon"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3BData(2)"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) স্কুল/হাসপাতাল/ ডিসপেনসারি' : '(7) Schools/Hospitals/Dispensaries' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_school"
                            v-model="stats1Data.individual_school"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3CData(2)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) অন্যান্য ফলজ ও অর্থকরী ফসল' : '(8) Other fruits and cash crops' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_other_fruits"
                            v-model="stats1Data.individual_other_fruits"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3BData(2)"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) রাস্তা, সেকশন রাস্তা সহ' : '(8) Roads, including section roads' }}</b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_road"
                            v-model="stats1Data.individual_road"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="cal3CData(2)"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th></b-th>
                        <b-th class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-৩ (খ)' : 'Subtotal-3 (B)' }}</b-th>
                        <b-th>{{$n(stats1Data.individual_other_agricultural_land)}}</b-th>
                        <b-th></b-th>
                        <b-th class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-৩ (গ)' : 'Subtotal-3 (C)' }}</b-th>
                        <b-th>{{$n(stats1Data.individual_non_agricultural_area)}}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="3">{{ currentLocale === 'bn' ? 'ইজারাকৃত মোট অনুমোদিত জমি উপ-মোট ২(ক) + ২(খ) + ৩(ক) + ৩(খ) + ৩(গ)' : 'Total sanctioned land leased Sub-total 2(a) + 2(b) + 3(a) + 3(b) + 3(c)' }}</b-td>
                      <b-td colspan="3">{{$n(stats1Data.individual_total_sanctioned_leased_land)}}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '৪ (ক)' : '4.(A)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা আবাদের শতকরা হার % ' : 'Percentage of tea plantation %' }}<small>{{ currentLocale === 'bn' ? '(চা বোর্ড কর্তৃক রেজিস্ট্রেশন ভুক্ত জমির তুলনায়)' : '(compared to land registered by Tea Board)' }}</small></b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_plan_per_com_leased_land"
                            v-model="stats1Data.individual_plan_per_com_leased_land"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>{{ currentLocale === 'bn' ? '৪ (খ)' : '4.(B)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা আবাদের শতকরা জার % ' : 'Percentage Jar % of Tea Plantation' }}<small>{{ currentLocale === 'bn' ? '(চা চাষ উপযোগী জমির তুলনায়)' : '(compared to land suitable for tea cultivation)' }}</small></b-td>
                        <b-td>
                          <b-form-input
                            plain
                            id="individual_plan_per_com_suitable_land"
                            v-model="stats1Data.individual_plan_per_com_suitable_land"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="mt-3" bordered responsive>
                <b-tbody>
                    <b-tr>
                        <b-td colspan="6"><strong>{{ currentLocale === 'bn' ? '৫. চা আবাদি শ্রেণীবিন্যাস (ইপ মোট ২(ক) অনুযায়ী)' : '5. Classification of tea plantations (as per IP MOT 2(a))' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(ক) ক্লোন (জাতের নাম উল্লেখসহ)- একর' : '(a) Clones (with mention of variety name)- Acres' }}</b-td>
                        <b-td colspan="3">
                          <b-form-input
                                plain
                                id="clone"
                                v-model="stats1Data.clone"
                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(খ) বাই/পলি ক্লোন (জাতের নাম উল্লেখসহ)- একর' : '(b) Bi/Poly clone (with mention of variety name)- Acres' }}</b-td>
                        <b-td colspan="3">
                          <b-form-input
                                plain
                                id="by_clone"
                                v-model="stats1Data.by_clone"
                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(গ) সাধারণ বীজ (জাতের নাম উল্লেখসহ)- একর' : '(c) Common seed (including variety name)- Acres' }}</b-td>
                        <b-td colspan="3">
                          <b-form-input
                                plain
                                id="common_seed"
                                v-model="stats1Data.common_seed"
                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="mt-3" bordered responsive>
                <b-tbody>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '৬. ক. ইজারা ভূক্ত জমির মধ্যে ভবিষ্যতে চা রোপণ যোগ্য জমির পরিমাণ (সম্ভাব্য)' : '6. A. Amount of future tea plantation area (potential) out of leased land' }}
                        </b-td>
                        <b-td colspan="3">
                          <b-form-input
                                plain
                                id="future_tea_plantation_land"
                                v-model="stats1Data.future_tea_plantation_land"
                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(খ) বাধ্যতামূলকভাবে সম্প্রসারণভূক্ত জমির মধ্যে প্রকৃত আবাদী জমির পরিমাণ' : '(b) Extent of actual cultivated land within the compulsorily expanded land' }}
                            <small class="d-block text-muted">{{ currentLocale === 'bn' ? '(অংশ - খ এর ক্রমিক ২ক+২ খ+৬ক অর্থাৎ ইজারাভূক্ত চা চাষযোগ্য মোট জমির ২.৫%)' : '(Sequential 2A+2B+6A of Part-B i.e. 2.5% of total tea cultivable land under lease)' }}</small>
                        </b-td>
                        <b-td colspan="3">
                          <b-form-input
                                plain
                                id="actual_cultivated_area"
                                v-model="stats1Data.actual_cultivated_area"
                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(গ) বাধ্যতামূলক সম্প্রসারণ আবাদ বাস্তবায়নঃ' : '(c) Implementation of Compulsory Extension Plantation:' }}</b-td>
                        <b-td colspan="3">
                          <b-form-input
                                plain
                                id="compulsory_extension_plantation"
                                v-model="stats1Data.compulsory_extension_plantation"
                              >
                              </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(ঘ) ২.৫% হারে বাধ্যতামূলক সম্প্রসারণ আবাদ বাস্তবায়ন না করলে তৎবিষয়ে সুপারিশ' : '(d) Recommendation on non-implementation of compulsory expansion plantations at the rate of 2.5%' }}</b-td>
                        <b-td colspan="3">
                          <b-form-input
                            plain
                            id="recommendation_non_implementation_en"
                            v-model="stats1Data.recommendation_non_implementation_en"
                            :placeholder="$t('globalTrans.en')"
                          >
                          </b-form-input>
                          <br>
                          <b-form-input
                            plain
                            id="recommendation_non_implementation_bn"
                            v-model="stats1Data.recommendation_non_implementation_bn"
                            :placeholder="$t('globalTrans.bn')"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(ঙ) বাগানের চা আবাদ সম্প্রসারণযোগ্য জমিতে কোন মামলা আছে কিনা, থাকলে বিস্তারিত বিবরণ' : '(e) whether there is any suit on garden tea plantation expandable land, if any details' }}</b-td>
                        <b-td colspan="3">
                          <b-form-input
                            plain
                            id="case_description_en"
                            v-model="stats1Data.case_description_en"
                            :placeholder="$t('globalTrans.en')"
                          >
                          </b-form-input>
                          <br>
                          <b-form-input
                            plain
                            id="case_description_bn"
                            v-model="stats1Data.case_description_bn"
                            :placeholder="$t('globalTrans.bn')"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্য (ইংরেজী)' : 'Comment (En):' }}</small>
                            <b-form-textarea
                              id="reason"
                              v-model="stats1Data.comment_en"
                              :placeholder="$t('globalTrans.en')"
                            ></b-form-textarea>
                        </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="6">
                        <small>{{ currentLocale === 'bn' ? 'মন্তব্য (বাংলা):' : 'Comment (Bn):' }}</small>
                        <b-form-textarea
                          id="reason"
                          v-model="stats1Data.comment_bn"
                          :placeholder="$t('globalTrans.bn')"
                        ></b-form-textarea>
                      </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
      </div>
    </b-overlay>
  </b-form>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { statsPartOneStoreUpdate } from '../../../../../api/routes'

 export default {
  props: ['loadingSubTab1', 'appDetailId', 'app_id', 'main_tab_data'],
    data () {
        return {
          stats1Data: {},
          loading: false
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    created () {
        this.stats1Data = this.main_tab_data
    },
    methods: {
      async saveUpdate () {
        this.loadingSubTab1 = true
        const keysToRemove = ['agriculture_machinery', 'dev_road_maps', 'factory_machinery', 'lab_wel_benefit', 'public_resource']

        keysToRemove.forEach(key => {
          if (key in this.stats1Data) {
            delete this.stats1Data[key]
          }
        })

        const params = Object.assign({}, this.stats1Data, { inspection_id: this.app_id })
        try {
          const response = await RestApi.postData(teaGardenServiceBaseUrl, statsPartOneStoreUpdate, params)
          if (response.success) {
            this.loadingSubTab1 = false
            this.$emit('stats-id-set', response.data.id)
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            return response
          } else {
            this.$refs.form.setErrors(response.data.errors)
          }
        } catch (error) {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: error,
            color: '#FF6347'
          })
        }
      },
      // // calculate 2 a data
      cal2AData (type) {
        const teaPlantKeys = [
          'immature_tea_plant',
          'new_tea_plant',
          'mature_tea_plant',
          'older_tea_plant',
          'very_old_tea_plant'
        ]

        const teaPlantPrefix = type === 1 ? 'gov_leased_' : 'individual_'
        const cultivationLandKey = `${teaPlantPrefix}cultivation_land`
        const totalCultivationLandKey = `${teaPlantPrefix}total_cultivation_land`
        const areasDontProduceTeaKey = `${teaPlantPrefix}areas_dont_produce_tea`

        let totalAData = 0

        teaPlantKeys.forEach(key => {
          const data = isNaN(parseFloat(this.stats1Data[`${teaPlantPrefix}${key}`])) ? 0 : parseFloat(this.stats1Data[`${teaPlantPrefix}${key}`])
          totalAData += data
        })

        this.stats1Data[cultivationLandKey] = totalAData
        this.stats1Data[totalCultivationLandKey] = totalAData + parseFloat(this.stats1Data[areasDontProduceTeaKey])
        this.calAll2and3Data(type)
      },
      cal2BData (type) {
        const nurseryKeys = [
          'clone_nursery',
          'seed_nursery',
          'shade_nursery',
          'seed_house',
          'ncp'
        ]

        const teaPlantPrefix = type === 1 ? 'gov_leased_' : 'individual_'
        const dontProduceTeaKey = `${teaPlantPrefix}areas_dont_produce_tea`
        const totalCultivationLandKey = `${teaPlantPrefix}total_cultivation_land`
        const cultivationLandKey = `${teaPlantPrefix}cultivation_land`

        let totalBData = 0

        nurseryKeys.forEach(key => {
          const data = isNaN(parseFloat(this.stats1Data[`${teaPlantPrefix}${key}`])) ? 0 : parseFloat(this.stats1Data[`${teaPlantPrefix}${key}`])
          totalBData += data
        })

        this.stats1Data[dontProduceTeaKey] = totalBData
        this.stats1Data[totalCultivationLandKey] = totalBData + parseFloat(this.stats1Data[cultivationLandKey])
        this.calAll2and3Data(type)
      },

      // calculate 3 Data
      cal3AData (type) {
        const teaProductionKeys = ['tea_production', 'soil_rehabilitation', 'tea_plantation_abandoned']

        const teaPlantPrefix = type === 1 ? 'gov_leased_' : 'individual_'
        const nonTeaAreaKey = `${teaPlantPrefix}non_tea_area`

        let total3AData = 0

        teaProductionKeys.forEach(key => {
          const data = !isNaN(parseFloat(this.stats1Data[`${teaPlantPrefix}${key}`])) ? parseFloat(this.stats1Data[`${teaPlantPrefix}${key}`]) : 0
          total3AData += data
        })

        this.stats1Data[nonTeaAreaKey] = total3AData
        this.calAll2and3Data(type)
      },

      cal3BData (type) {
        const keys = [
          'rubber',
          'bamboo',
          'plated_trees',
          'natural_forest',
          'mats',
          'paddy_field',
          'jack_fruit_lemon',
          'other_fruits'
        ]

        const prefix = type === 1 ? 'gov_leased_' : 'individual_'
        const targetKey = `${prefix}other_agricultural_land`

        let total3BData = 0

        keys.forEach(key => {
          const data = !isNaN(parseFloat(this.stats1Data[`${prefix}${key}`])) ? parseFloat(this.stats1Data[`${prefix}${key}`]) : 0
          total3BData += data
        })

        this.stats1Data[targetKey] = total3BData
        this.calAll2and3Data(type)
      },

     cal3CData (type) {
        const areaKeys = [
          'fallen_land',
          'water_bodies',
          'mosque',
          'grave',
          'labor_settlement',
          'factory',
          'school',
          'road'
        ]

        const leasedAreaPrefix = type === 1 ? 'gov_leased_' : 'individual_'
        const nonAgriculturalAreaKey = `${leasedAreaPrefix}non_agricultural_area`

        let total3CData = 0

        areaKeys.forEach(key => {
          const data = !isNaN(parseFloat(this.stats1Data[`${leasedAreaPrefix}${key}`])) ? parseFloat(this.stats1Data[`${leasedAreaPrefix}${key}`]) : 0
          total3CData += data
        })

        this.stats1Data[nonAgriculturalAreaKey] = total3CData
        this.calAll2and3Data(type)
     },

      calAll2and3Data (type) {
        const prefix = type === 1 ? 'gov_leased_' : 'individual_'

        const cultivationLandKey = `${prefix}cultivation_land`
        const areasDontProduceTeaKey = `${prefix}areas_dont_produce_tea`
        const nonTeaAreaKey = `${prefix}non_tea_area`
        const otherAgriLandKey = `${prefix}other_agricultural_land`
        const nonAgriAreaKey = `${prefix}non_agricultural_area`

        const totalAllData = parseFloat(this.stats1Data[cultivationLandKey]) + parseFloat(this.stats1Data[areasDontProduceTeaKey]) + parseFloat(this.stats1Data[nonTeaAreaKey]) + parseFloat(this.stats1Data[otherAgriLandKey]) + parseFloat(this.stats1Data[nonAgriAreaKey])

        this.stats1Data[`${prefix}total_sanctioned_leased_land`] = !isNaN(totalAllData) ? totalAllData : 0
      }

    }
 }
</script>
<style>
.porisonkhan-table-one tr:nth-child(-n+2) td:first-child,
.porisonkhan-table-one thead th:first-child,
.porisonkhan-table-one tr:nth-child(-n+2) td:nth-child(4),
.porisonkhan-table-one thead th:nth-child(4){
    text-align: center;
}
.porisonkhan-table-one td:nth-child(3),
.porisonkhan-table-one thead th:nth-child(3){
    border-right: 1px solid #ccc;
}
.porisonkhan-table-one thade th{
    border-bottom: 1px solid #dee2e6;
    background: #f9f9f9;
}
.porisonkhan-table-two{
    margin-top: 2rem;
}
.ev-tab-elements-tab-two .form-control{
    margin: 0;
}
</style>
