<template>
  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
    <b-overlay :show="loadingSubTab3">
    <!-- <pre>{{main_tab_data}}</pre> -->
      <div class="ev-tab-elements">
          <section class="">
              <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৮. (ক) যানবাহন, সেচ যন্ত্র, সেচের উৎস, কৃষি যন্ত্রপাতি, বিদ্যুৎ (জেনারেটর এবং ট্রান্সফরমারসহ):' : '8. (a) Vehicles, irrigation equipment, sources of irrigation, agricultural machinery, electricity (including generators and transformers):' }}</h6>
              <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <ValidationProvider name="Made Tea Type" vid="machine_id" rules="">
                    <b-form-group label-for="machine_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                          {{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="machine_id"
                        v-model="statsPartThreeAddMore.machine_id"
                        :options="machineNameList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <ValidationProvider name="Machine Number" vid="machine_number" rules="">
                    <b-form-group label-for="machine_number" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                          {{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="machine_number"
                        type="number"
                        v-model="statsPartThreeAddMore.machine_number"
                        :state="errors[0] ? false : (valid ? true : null)"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        min="0"
                      >
                      </b-form-input>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <ValidationProvider name="Shape" vid="new_addition" rules="">
                    <b-form-group label-for="new_addition" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                          {{ currentLocale === 'bn' ? 'নতুন সংযোজন' : 'New addition' }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="new_addition"
                        type="number"
                        v-model="statsPartThreeAddMore.new_addition"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <ValidationProvider name="Made Tea Type" vid="performance" rules="">
                    <b-form-group label-for="performance" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                          {{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        plain
                        id="performance"
                        v-model="statsPartThreeAddMore.performance"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="text-right">
                  <b-button type="button" size="sm" class="btn btn-success" @click="addItem()">
                    <i class="ri-add-circle-fill"></i> {{ $t('globalTrans.add_more') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-table-simple responsive bordered>
                <b-thead>
                  <b-tr>
                      <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'SN' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'যন্ত্রপাতির নাম' : 'Device Name' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'নতুন সংযোজন' : 'New addition' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <small class="text-muted d-block">{{ currentLocale === 'bn' ? '(কেজি/ঘন্টা, দিন)' : '(kg/hour, day)' }}</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'Action' : 'Action' }} <small class="text-muted d-block"></small></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="statsPartThree.details.length">
                    <b-tr v-for="(item, index) in statsPartThree.details" :key="index">
                      <b-td>{{ $n(index + 1) }}</b-td>
                      <b-td>{{ getMachineName(item.machine_id) }}</b-td>
                      <b-td>{{ $n(item.machine_number) }}</b-td>
                      <b-td>{{ $n(item.new_addition) }}</b-td>
                      <b-td>{{ item.performance }}</b-td>
                      <b-td>
                        <b-button type="button" size="sm" class="btn btn-danger" @click="deleteItem(index)">
                          <i class="ri-close-line"></i>
                        </b-button>
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td class="text-center" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
          </section>
          <section class="mt-4">
              <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '8. (খ) খরা মোকাবিলার জন্য সেচ ব্যবস্থার উন্নয়নে কি কি পরিকল্পনা গ্রহণ করা হয়েছেঃ' : '8. (b) What plans have been adopted for the development of irrigation system to combat drought:' }}</h6>
              <b-table-simple responsive bordered>
                  <b-tbody>
                      <b-tr>
                          <b-th>{{ currentLocale === 'bn' ? 'মন্তব্য লিখুন' : 'Write a comment' }}</b-th>
                          <b-td>
                            <b-form-textarea
                              id="development_plans_en"
                              v-model="stats3Data.development_plans_en"
                              :placeholder="$t('globalTrans.bn')"
                            ></b-form-textarea>
                            <br>
                            <b-form-textarea
                              id="development_plans_bn"
                              v-model="stats3Data.development_plans_bn"
                              :placeholder="$t('globalTrans.bn')"
                            ></b-form-textarea>
                          </b-td>
                      </b-tr>
                  </b-tbody>
              </b-table-simple>
          </section>
          <section class="">
              <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৯. (ক) জন সম্পদঃ' : '9. (a) Public resources:' }}</h6>
              <b-table-simple responsive bordered>
                  <b-thead class="text-center">
                      <b-tr>
                          <b-th rowspan="2">{{ currentLocale === 'bn' ? 'ক্র' : 'SL' }}</b-th>
                          <b-th rowspan="2">{{ currentLocale === 'bn' ? 'ক. নিবন্ধিত শ্রমিক' : 'A. registered worker' }}</b-th>
                          <b-th>জন</b-th>
                          <b-th rowspan="2">{{ currentLocale === 'bn' ? 'খ. অনিবন্ধিত শ্রমিক' : 'b. unregistered workers' }}</b-th>
                          <b-th>জন</b-th>
                          <b-th rowspan="2">{{ currentLocale === 'bn' ? 'সর্বমোট (ক+খ)' : 'Total (a+b)' }}</b-th>
                          <b-th colspan="4">{{ currentLocale === 'bn' ? 'বাগানে বসবাসকারী জনসংখ্যার তথ্য (বিগত ০৩ বছর)' : 'Data on population living in Bagan (last 03 years)' }}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th> {{ getFiscalYearName(currentFiscalYearId) }}</b-th>
                          <b-th> {{ getFiscalYearName(currentFiscalYearId) }}</b-th>
                          <b-th></b-th>
                          <!-- {{ publicResource }} -->
                          <template v-for="(fiscalItem, index) in publicResource">
                            <!-- {{ publicResource }} -->
                            <b-th :key="index">{{ getFiscalYearName(fiscalItem.fiscal_year_id) }}</b-th>
                          </template>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(১)' : '(1)' }}</b-td>
                          <b-td>{{ currentLocale === 'bn' ? 'পুরুষ' : 'Man' }}</b-td>
                          <b-td>
                            <b-form-input
                            plain
                            id="man_reg_worker"
                            v-model="stats3Data.man_reg_worker"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="calTotalWorker(stats3Data)"
                            >
                            </b-form-input></b-td>
                          <b-td>{{ currentLocale === 'bn' ? 'পুরুষ' : 'Man' }}</b-td>
                          <b-td>
                            <b-form-input
                            plain
                            id="test"
                            v-model="stats3Data.man_un_reg_worker"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            @keyup="calTotalWorker(stats3Data)"
                            >
                            </b-form-input></b-td>
                          <b-td>
                              {{$n(totalManWorker)}}
                          </b-td>
                          <b-td>{{ currentLocale === 'bn' ? 'পুরুষ' : 'Man' }}</b-td>
                          <b-td v-for="(obj, index) in publicResource" :key="'total_man_in_garden-' + index">
                            <b-form-input
                              plain
                              v-model="obj.total_man_in_garden"
                              @keyup="calTotalManWomanGarden(obj.fiscal_year_id)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td>{{ currentLocale === 'bn' ? '(২)' : '(2)' }}</b-td>
                          <b-td>{{ currentLocale === 'bn' ? 'মহিলা' : 'Woman' }}</b-td>
                          <b-td>
                            <b-form-input
                            plain
                            id="woman_reg_worker"
                            v-model="stats3Data.woman_reg_worker"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              @keyup="calTotalWorker(stats3Data)"
                            >
                            </b-form-input></b-td>
                          <b-td>{{ currentLocale === 'bn' ? 'মহিলা' : 'Woman' }}</b-td>
                          <b-td>
                            <b-form-input
                            plain
                            id="woman_un_reg_worker"
                            v-model="stats3Data.woman_un_reg_worker"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              @keyup="calTotalWorker(stats3Data)"
                            >
                            </b-form-input></b-td>
                          <b-td>{{$n(totalWomanWorker)}}</b-td>
                          <b-td>{{ currentLocale === 'bn' ? 'মহিলা' : 'Woman' }}</b-td>
                          <b-td v-for="(obj, index) in publicResource" :key="'total_woman_in_garden-' + index">
                            <b-form-input
                              plain
                              v-model="obj.total_woman_in_garden"
                              @keyup="calTotalManWomanGarden(obj.fiscal_year_id)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                            </b-form-input>
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-td></b-td>
                          <b-td><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                          <b-td>{{ $n(totalRegWorker)}}</b-td>
                          <b-td><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                          <b-td>{{$n(totalUnRegWorker)}}</b-td>
                          <b-td>{{$n(totalWorker)}}</b-td>
                          <b-td><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                          <b-td v-for="(obj, index) in publicResource" :key="'total_people_in_garden-' + index">
                            {{$n(obj.total_people_in_garden)}}
                          </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্য (ইংরেজী)' : 'Comment (En):' }}</small>
                            <b-form-textarea
                              id="public_resource_comment_en"
                              v-model="stats3Data.public_resource_comment_en"
                              :placeholder="$t('globalTrans.en')"
                            ></b-form-textarea>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td colspan="10">
                          <small>{{ currentLocale === 'bn' ? 'মন্তব্য (বাংলা):' : 'Comment (Bn):' }}</small>
                          <b-form-textarea
                            id="public_resource_comment_bn"
                            v-model="stats3Data.public_resource_comment_bn"
                            :placeholder="$t('globalTrans.bn')"
                          ></b-form-textarea>
                        </b-td>
                      </b-tr>
                  </b-tbody>
              </b-table-simple>
          </section>
      </div>
    </b-overlay>
  </b-form>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { statsPartThreeStoreUpdate } from '../../../../../api/routes'
export default {
  props: ['loadingSubTab3', 'appDetailId', 'app_id', 'main_tab_data', 'stats_id'],
  data () {
      return {
        currentFiscalYearId: 0,
        publicResource: [],
        stats3Data: {},
        totalRegWorker: 0,
        totalUnRegWorker: 0,
        totalWorker: 0,
        totalManWorker: 0,
        totalWomanWorker: 0,
        statsPartThree: {
          details: []
        },
        statsPartThreeAddMore: {
          machine_id: 1,
          machine_number: '',
          new_addition: '',
          performance: ''
        }
      }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    machineNameList () {
      return this.$store.state.TeaGardenService.commonObj.machineNameList.filter(item => item.status === 1 && item.type === 2)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  created () {
    this.currentFiscalYearId = this.$store.state.currentFiscalYearId
    if (this.main_tab_data && this.main_tab_data.public_resource?.length > 0) {
      this.statsPartThree.details = this.main_tab_data.agriculture_machinery ? this.main_tab_data.agriculture_machinery : []
      this.publicResource = this.main_tab_data.public_resource
      this.stats3Data = this.main_tab_data
      this.publicResource.map(item => {
        if (item.fiscal_year_id === parseInt(this.$store.state.currentFiscalYearId)) {
          this.stats3Data = { ...this.stats3Data, ...item }
        }
      })
    } else {
      this.previousTwoYearFiscalYearId()
    }
    this.calTotalWorker(this.stats3Data)
  },
  methods: {
    addItem () {
      if (!this.statsPartThreeAddMore.machine_id) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please select Machine!!!'
        })
      }
      if (this.isMachineExists(this.statsPartThree, this.statsPartThreeAddMore.machine_id)) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Machine Already Exists!!!'
        })
      }

      this.statsPartThree.details.push({ ...this.statsPartThreeAddMore })
      this.resetAddMore()
    },
    deleteItem (index) {
      this.statsPartThree.details.splice(index, 1)
    },
    isMachineExists (statsPart, machineId) {
      return statsPart.details.some(item => item.machine_id === machineId)
    },
    resetAddMore () {
      this.statsPartThreeAddMore.machine_id = 0
      this.statsPartThreeAddMore.machine_number = ''
      this.statsPartThreeAddMore.new_addition = ''
      this.statsPartThreeAddMore.performance = ''
    },
    getMachineName (id) {
      const obj = this.machineNameList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    previousTwoYearFiscalYearId () {
      const obj = this.fiscalYearList.find(item => item.value === parseInt(this.$store.state.currentFiscalYearId))
      const fiscalYearListSort = this.fiscalYearList.filter(item => item.sorting_order <= obj.sorting_order).sort((a, b) => b.sorting_order - a.sorting_order)
      const publicResource = []
      fiscalYearListSort.forEach(item => {
        if (publicResource.length < 3) {
          publicResource.push({
            fiscal_year_id: item.value,
            total_man_in_garden: '',
            total_woman_in_garden: '',
            total_people_in_garden: ''
          })
        }
      })
      this.publicResource = publicResource
    },
    getFiscalYearName (id) {
      const data = this.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    calTotalWorker (stats3Data) {
      const manUn = !isNaN(parseFloat(stats3Data.man_un_reg_worker)) ? parseFloat(stats3Data.man_un_reg_worker) : 0
      const manReg = !isNaN(parseFloat(stats3Data.man_reg_worker)) ? parseFloat(stats3Data.man_reg_worker) : 0
      const womanUn = !isNaN(parseFloat(stats3Data.woman_un_reg_worker)) ? parseFloat(stats3Data.woman_un_reg_worker) : 0
      const womanReg = !isNaN(parseFloat(stats3Data.woman_reg_worker)) ? parseFloat(stats3Data.woman_reg_worker) : 0
      stats3Data.total_reg_worker = manReg + womanReg
      stats3Data.total_un_reg_worker = manUn + womanUn
      stats3Data.total_worker = stats3Data.total_reg_worker + stats3Data.total_un_reg_worker
      stats3Data.total_man_worker = manUn + manReg
      stats3Data.total_woman_worker = womanUn + womanReg
      // for new
      this.totalRegWorker = manReg + womanReg
      this.totalUnRegWorker = manUn + womanUn
      this.totalWorker = stats3Data.total_reg_worker + stats3Data.total_un_reg_worker
      this.totalManWorker = manUn + manReg
      this.totalWomanWorker = womanUn + womanReg
    },
    calTotalManWomanGarden (fiscalYearId) {
       const obj = this.publicResource.find(item => item.fiscal_year_id === parseInt(fiscalYearId))
       const totalMan = !isNaN(parseFloat(obj.total_man_in_garden)) ? parseFloat(obj.total_man_in_garden) : 0
       const totalWoman = !isNaN(parseFloat(obj.total_woman_in_garden)) ? parseFloat(obj.total_woman_in_garden) : 0
       obj.total_people_in_garden = totalMan + totalWoman
    },
    async saveUpdate () {
      this.loadingSubTab3 = true
      const keysToRemove = ['agriculture_machinery', 'dev_road_maps', 'factory_machinery', 'lab_wel_benefit', 'public_resource']

        keysToRemove.forEach(key => {
          if (key in this.stats3Data) {
            delete this.stats3Data[key]
          }
        })

      this.publicResource.map((item, index) => {
        if (item.fiscal_year_id === parseInt(this.$store.state.currentFiscalYearId)) {
          this.publicResource[index] = { ...item, ...this.stats3Data }
        }
      })
      const statsMainData = {
        public_resource_comment_en: this.stats3Data.public_resource_comment_en,
        public_resource_comment_bn: this.stats3Data.public_resource_comment_bn,
        development_plans_en: this.stats3Data.development_plans_en,
        development_plans_bn: this.stats3Data.development_plans_bn
      }
      if (this.statsPartThree.details.length > 0) {
        const params = Object.assign({}, statsMainData, { stat_info_id: this.stats_id, publicResource: this.publicResource, agricultureMachine: this.statsPartThree.details })
        try {
          const response = await RestApi.postData(teaGardenServiceBaseUrl, statsPartThreeStoreUpdate, params)
          if (response.success) {
            this.loadingSubTab3 = false
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.stats_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            return response
          } else {
            this.$refs.form.setErrors(response.data.errors)
          }
        } catch (error) {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: error,
            color: '#FF6347'
          })
        }
      } else {
          this.loadingSubTab3 = false
          this.$toast.warn({
                title: this.$i18n.locale === 'en' ? 'Warning..!' : 'সতর্কতা..!',
                message: this.$i18n.locale === 'en' ? 'At least one machinery need to add' : 'অন্তত একটি যন্ত্রপাতি যোগ করতে হবে।',
                color: '#F5A623',
                timeOut: 6000

          })
      }
    }
  }
}
</script>
<style></style>
