<template>
  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
    <b-overlay :show="loadingSubTab5">
    <div class="ev-tab-elements">
        <section class="Implementation-development-roadmap">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১১. উন্নয়নের পথনকশা বাস্তবায়ন' : '11. Implementation of development roadmap' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? $n(parseInt(year)-5, {useGrouping:false})+'-'+$n((parseInt(year)-5)+4, {useGrouping:false})+' পর্যন্ত বাস্তবায়ন অগ্রগতি (স্বল্প মেয়াদী)' : 'Implementation progress up to '+$n(parseInt(year)-5, {useGrouping:false})+'-'+$n((parseInt(year)-5)+4, {useGrouping:false})+' (short term)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? $n(parseInt(year), {useGrouping:false})+'-'+$n(parseInt(year)+4, {useGrouping:false})+'পর্যন্ত বাস্তবায়ন লক্ষ্যমাত্রা (মধ্যমেয়াদী)' : 'Implementation Targets for '+$n(parseInt(year), {useGrouping:false})+'-'+$n(parseInt(year)+4, {useGrouping:false})+ ' (Medium Term)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? $n(parseInt(year)-5, {useGrouping:false})+' সাল হতে পরিদর্শনকালীন '+ getMonth() +'/'+ $n(parseInt(year), {useGrouping: false}) +' মাস পর্যন্ত বাস্তবায়ন অগ্রগতি' : 'Implementation progress from '+$n(parseInt(year)-5, {useGrouping:false})+' till ' + getMonth() +'/'+ $n(parseInt(year), {useGrouping: false}) }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'চলতি বছরের '+ getMonth() +'/'+ $n(parseInt(year), {useGrouping: false}) +' মাস পর্যন্ত বাস্তবায়ন অগ্রগতি' : 'Implementation progress till '+ getMonth() +'/'+ $n(parseInt(year), {useGrouping: false}) +' this year' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১) অপরিণত ও পরিণত এলাকায় শূন্যস্থানে নতুন চারা রোপণ (সংখ্যা)' : '(1) Planting of new seedlings in open spaces in immature and mature areas (number)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.new_seedling_plan_imp_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.new_seedling_plan_imp_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.new_seedling_plan_imp_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.new_seedling_plan_imp_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) চা পুনরাবাদ/খন্ড পুনরাবাদ (একর)' : '(2) Tea Replantation/Area Replantation (Acres)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_replantation_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_replantation_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_replantation_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_replantation_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) চা এলাকা সম্প্রসারণ (একর)' : '(3) Expansion of tea area (acres)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_area_exp_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_area_exp_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_area_exp_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.tea_area_exp_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) চা বাগানের জন্য যানবাহন ক্রয়ের পরিমাণ (ট্রাক্টর, ট্রেইলর, ট্রেইলর সহ পাওয়ার টিলার)' : '(4) Amount of purchase of vehicles for tea plantations (tractors, trailers, power tillers with trailers)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.vehicle_purchase_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.vehicle_purchase_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.vehicle_purchase_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.vehicle_purchase_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) চা বাগানের জন্য সেচ যন্ত্র ক্রয়ের সংখ্যা (৫০ একর, ১০০ একর, ভূ-গর্ভস্থ)' : '(5) Number of Irrigation Equipment Purchased for Tea Gardens (50 Acres, 100 Acres, Underground)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.irri_equ_purchase_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.irri_equ_purchase_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.irri_equ_purchase_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.irri_equ_purchase_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) কীটনাশকের জন্য স্প্রেয়ার ক্রয়ের পরিমাণ (হস্তচালিত, পাওয়ার স্প্রেয়ার)' : '(6) Amount of Purchase of Sprayers for Pesticides (Manual, Power Sprayers)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.sprayer_purchase_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.sprayer_purchase_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.sprayer_purchase_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.sprayer_purchase_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) চা কারখানা আধুনিকীকরণের জন্য যন্ত্র ক্রয় (যন্ত্রের নাম ও সংখ্যা)' : '(7) Purchase of machinery for modernization of tea factories (name and number of machinery).' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.machinery_purchase_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.machinery_purchase_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.machinery_purchase_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.machinery_purchase_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) চা বাগানসমূহের অবকাঠামো উন্নয়ন (রাস্তা, কালভার্ট, ব্রিজ নির্মাণের সংখ্যা)' : '(8) Development of infrastructure in tea gardens (number of construction of roads, culverts, bridges)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.infra_develop_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.infra_develop_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.infra_develop_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.infra_develop_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৯) বাঁধ/জলাধার নির্মাণের সংখ্যা' : '(9) Number of dams/reservoirs constructed' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.dam_number_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.dam_number_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.dam_number_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.dam_number_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১০) শ্রমিকদের জন্য স্বাস্থ্যসম্মত বাসস্থান নির্মাণের সংখ্যা' : '(9) Number of dams/reservoirs constructed' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_res_cons_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_res_cons_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_res_cons_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_res_cons_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১১) শ্রমিকদের জন্য স্বাস্থ্যসম্মত শৌচাগার নির্মাণের সংখ্যা' : '(11) Number of construction of sanitary latrines for workers' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_lat_cons_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_lat_cons_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_lat_cons_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.san_lat_cons_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১২) পানীয় জলের সুবিধা (হস্তচালিত নলকূপ, গভীর নলকূপ, পাতকুয়া নির্মাণের সংখ্যা)' : '(12) Drinking water facilities (number of construction of manual tube wells, deep tube wells, water wells)' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.water_facilities_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.water_facilities_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.water_facilities_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.water_facilities_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১৩) স্বাস্থ্যসেবা-হাসপাতাল/ক্লিনিক/ডিসপেনসারি নির্মাণের সংখ্যা' : '(13) Healthcare-No of construction of hospitals/clinics/dispensaries' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.hospital_cons_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.hospital_cons_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.hospital_cons_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.hospital_cons_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১৪) চা বাগানে শ্রমিকদের জন্য ক্রেস হাউস নির্মাণের সংখ্যা' : '(14) No. of construction of cress houses for laborers in tea plantations' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.cress_hou_cons_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.cress_hou_cons_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.cress_hou_cons_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.cress_hou_cons_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১৫) চা বাগানে মাদার্স ক্লাব নির্মাণ' : '(15) Construction of Mother"s Club in Tea Gardens' }}</b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.mother_club_cons_pro_prev_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.mother_club_cons_tar_next_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.mother_club_cons_pro_for_five_years"
                          >
                          </b-form-input>
                        </b-td>
                        <b-td>
                          <b-form-input
                          plain
                          id="id"
                          v-model="stats5Data.mother_club_cons_pro_till_cur_years"
                          >
                          </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                          <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ (En)' : 'Comment (En):' }} </small>
                          <b-form-textarea
                            id="reason"
                            v-model="stats5Data.comment_en"
                            >
                        </b-form-textarea>
                        </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="10">
                        <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ (Bangla)' : 'Comment: (Bn)' }} </small>
                        <b-form-textarea
                          id="reason"
                          v-model="stats5Data.comment_bn"
                        ></b-form-textarea>
                      </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
    </b-overlay>
  </b-form>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { statsPartFiveStoreUpdate } from '../../../../../api/routes'

export default {
  props: ['loadingSubTab5', 'appDetailId', 'app_id', 'main_tab_data', 'visitingDate', 'stats_id'],
  data () {
    return {
      monthNumber: 0,
      monthTextual: '',
      year: '',
      stats5Data: {}
    }
  },
  computed: {
      currentLocale () {
          return this.$i18n.locale
      }
  },
  created () {
    const inputDate = this.visitingDate
    const dateParts = inputDate.split('-')
    this.year = parseInt(dateParts[0])
    this.monthNumber = parseInt(dateParts[1])

    if (this.main_tab_data && this.main_tab_data.dev_road_maps) {
      this.stats5Data = this.main_tab_data.dev_road_maps
    }
  },
  methods: {
    getMonth () {
        const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
      const bengaliMonths = [
          'জানুয়ারি', 'ফেব্রুয়ারি', 'মার্চ', 'এপ্রিল', 'মে', 'জুন',
          'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর'
      ]
      const monthTextual = this.currentLocale === 'bn' ? bengaliMonths[this.monthNumber - 1] : months[this.monthNumber - 1]
      return monthTextual
    },
    async saveUpdate () {
      this.loadingSubTab5 = true
      const params = Object.assign({}, this.stats5Data, { stat_info_id: this.stats_id })
      try {
        const response = await RestApi.postData(teaGardenServiceBaseUrl, statsPartFiveStoreUpdate, params)
        if (response.success) {
          this.loadingSubTab5 = false
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.stats_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          return response
        } else {
          this.$refs.form.setErrors(response.data.errors)
        }
      } catch (error) {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: error,
          color: '#FF6347'
        })
      }
    }
  }
}
</script>
<style>
.Implementation-development-roadmap table tr th,
.Implementation-development-roadmap table tr td{
    vertical-align: middle;
}
</style>
