<template>
<div>
  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
        <b-overlay :show="loadingSubTab2">
        <!-- <pre>{{stats2Data}}</pre> -->
          <div class="ev-tab-elements">
            <section class="">
              <div>
                <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (ক) চা কারখানায় ব্যবহৃত যন্ত্রপাতি এবং এগুলোর কর্মক্ষমতাঃ' : '7. (a) Machinery used in tea factories and their performance:' }}</h6>
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="machine_id" rules="">
                      <b-form-group label-for="machine_id" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'যন্ত্রপাতির নাম' : 'Device Name' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          id="machine_id"
                          v-model="statsSecondPartAddMore.machine_id"
                          :options="factoryMachineNameList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Machine Number" vid="machine_number" rules="">
                      <b-form-group label-for="machine_number" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="machine_number"
                          v-model="statsSecondPartAddMore.machine_number"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Shape" vid="shape" rules="">
                      <b-form-group label-for="shape" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'আকৃতি' : 'Shape' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="shape"
                          v-model="statsSecondPartAddMore.shape"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Addition Date" vid="addition_date" rules="">
                      <b-form-group label-for="addition_date" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'সংযোজনের তারিখ' : 'Date of Addition' }} <span class="text-danger">*</span>
                        </template>
                        <date-picker
                          id="addition_date"
                          v-model="statsSecondPartAddMore.addition_date"
                          class="form-control"
                          :placeholder="$t('globalTrans.select_date')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :class="errors[0] ? 'is-invalid' : ''"
                          >
                        </date-picker>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="performance" rules="">
                      <b-form-group label-for="performance" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          plain
                          id="performance"
                          v-model="statsSecondPartAddMore.performance"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <b-button type="button" size="sm" class="btn btn-success" @click="addItem(1)">
                      <i class="ri-add-circle-fill"></i> {{ $t('globalTrans.add_more') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple responsive bordered>
                  <b-thead>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'SN' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'যন্ত্রপাতির নাম' : 'Device Name' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'আকৃতি' : 'Shape' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংযোজনের তারিখ' : 'Date of Addition' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <small class="text-muted d-block">{{ currentLocale === 'bn' ? '(কেজি/ঘন্টা, দিন)' : '(kg/hour, day)' }}</small></b-th>
                        <b-th>{{ $t('globalTrans.action') }} <small class="text-muted d-block"></small></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="statsSecondPart.details.length">
                      <b-tr v-for="(item, index) in statsSecondPart.details" :key="index">
                        <b-td>{{ $n(index + 1) }}</b-td>
                        <b-td>{{ getMachineName(item.machine_id) }}</b-td>
                        <b-td>{{ $n(item.machine_number) }}</b-td>
                        <b-td>{{ item.shape }}</b-td>
                        <b-td>{{ item.addition_date | dateFormat }}</b-td>
                        <b-td>{{ item.performance }}</b-td>
                        <b-td>
                          <b-button type="button" size="sm" class="btn btn-danger" @click="deleteItem(1, index)">
                            <i class="ri-close-line"></i>
                          </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="7">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </div>
            </section>
            <section class="mt-4">
              <div>
                <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (খ) চা কারখানায় ২০২০-২০২১ অর্থবছরে নতুন সংযোজিত যন্ত্রপাতি এবং এগুলোর কর্মক্ষমতাঃ' : '7. (b) Newly added machinery in tea factories during the financial year 2020-2021 and their performance:' }}</h6>
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="machine_id" rules="">
                      <b-form-group label-for="machine_id" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'যন্ত্রপাতির নাম' : 'Device Name' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          id="machine_id"
                          v-model="statsSecondPartAddMore2.machine_id"
                          :options="AgriMachineNameList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Machine Number" vid="machine_number" rules="">
                      <b-form-group label-for="machine_number" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="machine_number"
                          v-model="statsSecondPartAddMore2.machine_number"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Shape" vid="shape" rules="">
                      <b-form-group label-for="shape" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'আকৃতি' : 'Shape' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="shape"
                          v-model="statsSecondPartAddMore2.shape"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Addition Date" vid="addition_date" rules="">
                      <b-form-group label-for="addition_date" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'সংযোজনের তারিখ' : 'Date of Addition' }} <span class="text-danger">*</span>
                        </template>
                        <date-picker
                          id="addition_date"
                          v-model="statsSecondPartAddMore2.addition_date"
                          class="form-control"
                          :placeholder="$t('globalTrans.select_date')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :class="errors[0] ? 'is-invalid' : ''"
                          >
                        </date-picker>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="performance" rules="">
                      <b-form-group label-for="performance" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          plain
                          id="performance"
                          v-model="statsSecondPartAddMore2.performance"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <b-button type="button" size="sm" class="btn btn-success" @click="addItem(2)">
                      <i class="ri-add-circle-fill"></i> {{ $t('globalTrans.add_more') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple responsive bordered>
                  <b-thead>
                    <b-tr>
                      <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'SN' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'যন্ত্রপাতির নাম' : 'Device Name' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'আকৃতি' : 'Shape' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'সংযোজনের তারিখ' : 'Date of Addition' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                      <b-th>{{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <small class="text-muted d-block">{{ currentLocale === 'bn' ? '(কেজি/ঘন্টা, দিন)' : '(kg/hour, day)' }}</small></b-th>
                      <b-th>{{ $t('globalTrans.action') }} <small class="text-muted d-block"></small></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="statsSecondPart2.details.length">
                      <b-tr v-for="(item, index) in statsSecondPart2.details" :key="index">
                        <b-td>{{ $n(index + 1) }}</b-td>
                        <b-td>{{ getMachineName(item.machine_id) }}</b-td>
                        <b-td>{{ $n(item.machine_number) }}</b-td>
                        <b-td>{{ item.shape }}</b-td>
                        <b-td>{{ item.addition_date | dateFormat }}</b-td>
                        <b-td>{{ item.performance }}</b-td>
                        <b-td>
                          <b-button type="button" size="sm" class="btn btn-danger" @click="deleteItem(2,index)">
                            <i class="ri-close-line"></i>
                          </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="7">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </div>
            </section>
            <section class="mt-4">
              <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (গ) চা অবস্থিত চা পক্রিয়াজাত কারখানাটির বাংলাদেশ চা বোর্ড কর্তৃক পদত্ত নিবন্ধন নম্বারঃ' : '7. (c) Registration number issued by the Bangladesh Tea Board of the tea processing factory where the tea is located:' }}</h6>
              <b-table-simple responsive bordered>
                <b-tbody>
                  <b-tr>
                    <b-th width="50%">{{ currentLocale === 'bn' ? 'নিবন্ধন নাম্বার' : 'Registration No' }}</b-th>
                    <b-td width="50%">
                      <ValidationProvider name="factory_registration_no" vid="factory_registration_no" rules="">
                        <b-form-group label-for="factory_registration_no" slot-scope="{ valid, errors }">
                          <b-form-input
                            plain
                            id="factory_registration_no"
                            v-model="stats2Data.factory_registration_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </section>
            <section class="mt-4">
              <b-row>
                <b-col>
                  <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (ঘ) পরিদর্শনকালীন পর্যন্ত চা প্রত্রিয়াতাত কারখানাটির নিবন্ধন নবায়ন করা হয়েছে কিনাঃ' : '7. (d) Whether the registration of the tea factory has been renewed up to the time of inspection:' }}</h6>
                </b-col>
                <b-col>
                  <b-form-checkbox
                        :id="'checkbox'"
                        v-model="stats2Data.is_new_factory_registration"
                        :name="'checkbox'"
                        value=1
                        unchecked-value=0
                      >
                      </b-form-checkbox>
                </b-col>
              </b-row>
            </section>
            <section class="mt-4">
              <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (ঙ) চা বাগানের চা প্রত্রিয়াজাত কারখানা না থাকলে কাঁচা চা পাতা বিক্রয় সম্পর্কিত বিস্তারিতঃ' : '7. (e) Details regarding sale of raw tea leaves if there is no tea factory in the tea garden:' }}</h6>
              <b-table-simple responsive bordered>
                <b-tbody>
                  <b-tr>
                    <b-th width="50%">{{ currentLocale === 'bn' ? 'বিবরণ লিখুন (En)' : 'Enter details (En)' }}</b-th>
                    <b-td width="50%">
                      <ValidationProvider name="comment_en" vid="comment_en" rules="">
                        <b-form-group label-for="comment_en" slot-scope="{ valid, errors }">
                          <b-form-input
                            plain
                            id="comment_en"
                            v-model="stats2Data.tea_leave_sales_description_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="50%">{{ currentLocale === 'bn' ? 'বিবরণ লিখুন (Bn)' : 'Enter details (Bn)' }}</b-th>
                    <b-td width="50%">
                      <ValidationProvider name="comment_en" vid="comment_en" rules="">
                        <b-form-group label-for="comment_en" slot-scope="{ valid, errors }">
                          <b-form-input
                            plain
                            id="comment_en"
                            v-model="stats2Data.tea_leave_sales_description_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </section>
          </div>
        </b-overlay>
      </b-form>
  </ValidationObserver>
</div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { statsPartTwoStoreUpdate } from '../../../../../api/routes'
import { helpers } from '@/utils/helper-functions'

export default {
  props: ['loadingSubTab2', 'appDetailId', 'app_id', 'main_tab_data'],
  data () {
    return {
      stats2Data: {},
      statsSecondPart: {
        details: []
      },
      statsSecondPartAddMore: {
        type: 1,
        machine_id: 1,
        machine_number: 0,
        shape: '',
        performance: '',
        addition_date: helpers.currentDate()
      },
      statsSecondPart2: {
        details: []
      },
      statsSecondPartAddMore2: {
        type: 2,
        machine_id: 1,
        machine_number: 0,
        shape: '',
        performance: '',
        addition_date: helpers.currentDate()
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    factoryMachineNameList () {
      return this.$store.state.TeaGardenService.commonObj.machineNameList.filter(item => item.status === 1 && item.type === 1)
    },
    machineNameList () {
      return this.$store.state.TeaGardenService.commonObj.machineNameList.filter(item => item.status === 1)
    },
    AgriMachineNameList () {
      return this.$store.state.TeaGardenService.commonObj.machineNameList.filter(item => item.status === 1 && item.type === 2)
    }
  },
  created () {
    this.stats2Data = this.main_tab_data
    if (this.main_tab_data && this.main_tab_data.factory_machinery) {
      this.main_tab_data.factory_machinery.map(item => {
        if (item.type === 1) {
          this.statsSecondPart.details.push(item)
        }
        if (item.type === 2) {
          this.statsSecondPart2.details.push(item)
        }
      })
    }
  },
  methods: {
    addItem (type) {
      const statsPart = type === 1 ? this.statsSecondPart : this.statsSecondPart2
      const statsPartAddMore = type === 1 ? this.statsSecondPartAddMore : this.statsSecondPartAddMore2

      if (!statsPartAddMore.machine_id) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please select Machine'
        })
      }
      if (this.isMachineExists(statsPart, statsPartAddMore.machine_id)) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('globalTrans.machineAlreadyExists')
        })
      }

      statsPart.details.push({ ...statsPartAddMore, type })
      this.resetAddMore(statsPartAddMore, type)
    },
    deleteItem (type, index) {
      const statsPart = type === 1 ? this.statsSecondPart : this.statsSecondPart2
      statsPart.details.splice(index, 1)
    },
    isMachineExists (statsPart, machineId) {
      return statsPart.details.some(item => item.machine_id === machineId)
    },
    resetAddMore (statsPartAddMore, type) {
      statsPartAddMore.machine_id = 0
      statsPartAddMore.machine_number = 0
      statsPartAddMore.shape = ''
      statsPartAddMore.performance = ''
      statsPartAddMore.addition_date = ''
      statsPartAddMore.type = type
    },
    getMachineName (id) {
      const obj = this.machineNameList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async saveUpdate () {
      this.loadingSubTab2 = true
      const { statsSecondPart, statsSecondPart2 } = this
      const combineTwoAddMore = [...statsSecondPart.details, ...statsSecondPart2.details]
      if (combineTwoAddMore.length > 0) {
          const params = Object.assign({}, this.stats2Data, { inspection_id: this.app_id, machineries: combineTwoAddMore })
          try {
            const response = await RestApi.postData(teaGardenServiceBaseUrl, statsPartTwoStoreUpdate, params)
            if (response.success) {
              this.loadingSubTab2 = false
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })
              return response
            } else {
              this.$refs.form.setErrors(response.data.errors)
            }
          } catch (error) {
            this.$toast.error({
              title: this.$t('globalTrans.error'),
              message: error,
              color: '#FF6347'
            })
          } finally {
          }
      } else {
         this.loadingSubTab2 = false
          this.$toast.warn({
                title: this.$i18n.locale === 'en' ? 'Warning..!' : 'সতর্কতা..!',
                message: this.$i18n.locale === 'en' ? 'At least one machinery need to add' : 'অন্তত একটি যন্ত্রপাতি যোগ করতে হবে।',
                color: '#F5A623',
                timeOut: 6000

          })
      }
    }
  }
}
</script>
